import React, {createContext, useState, useContext, useCallback, useEffect} from 'react';
import Web3Context from "./Web3Context";
import {useSelector} from "react-redux";

const TokenBalanceContext = createContext({})


const TokenBalanceProvider = ({children}) => {
    const web = useSelector((state) => state.web);
    const {web3,tokenContract} = useContext(Web3Context);
    const [tokenSymbol, setTokenSymbol] = useState(process.env.REACT_APP_TOKEN_SYMBOL);
    const [tokenBalance, setTokenBalance] = useState(0);
  
    const refetchTokenBalance = useCallback(async () => {
      if (web.account && tokenContract) {
        const newBalance = await tokenContract.methods.balanceOf(web.account).call();
        //wei to eth
        const balance =await web3.utils.fromWei(newBalance, 'ether');
        setTokenBalance(balance);
      }
      //console.log('refetchTokenBalance')
    }, [tokenContract, web.account])
  

    useEffect(() => {
      refetchTokenBalance();
    }, [refetchTokenBalance])

    return (
      <TokenBalanceContext.Provider value={{ tokenBalance,tokenSymbol, refetchTokenBalance }}>
        {children}
      </TokenBalanceContext.Provider>
    );
  }
  
  export {TokenBalanceProvider, TokenBalanceContext}

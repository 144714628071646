import React, { useState, useEffect } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getExcerpt } from "../../libs/helper";
import { gamerActions } from "../../store/gamer";
import { fetchManagers } from "../../store/info";
import Snackbar from "@mui/material/Snackbar";
import axiosIns from "../../libs/axios";
import MuiAlert from "@mui/material/Alert";
import Swal from "sweetalert2";

const AccountPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gamer = useSelector((state) => state.gamer && state.gamer.gamer);
  const managerIds = useSelector((state) => state.info && state.info.managerIds);
  const playerIds = useSelector((state) => state.info && state.info.playerIds);

  const [name, setName] = useState((gamer && gamer.name) || "");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setName((gamer && gamer.name) || "");
  }, [gamer.name]);

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchManagers(accessToken));
    }
  }, [dispatch, accessToken]);


  const handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      title: t("account.warning_delete_account"),
      html: `${t("account.warning_delete_account_text")}`,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Delete!",
      cancelButtonText: "Cancel",
      padding: "2em",
      //background: `#fff url(${banner && banner.image_url})`,
      backdrop: `
            rgba(0,0,123,0.4)
          `,
      customClass: {
        confirmButton: 'background-danger'
      },
    }).then(res => {

      if (res.isConfirmed) {
        axiosIns
        .delete("/gamers/data", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        })
        .then((res) => {
          Swal.fire({
            title: t("account.warning_success_delete_account_title"),
            html: `${t("account.warning_success_delete_account_text")}`,
          })
          //refresh page
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            html: `${err}`,
          })
        })
      }
    })

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("name", name);
    formData.append("image", e.target.file.files[0]);

    axiosIns
      .post("/gamers", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        handleClick();
        dispatch(gamerActions.setGamer(res.data.gamer));
      });

    //dispatch(updateGamer({accessToken: localStorage.getItem('accessToken'), data: formData, title: t("account.warning_update_success_title"), text: t("account.warning_update_success_text")}));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <MainBanner slug="account-center" title={t("account.header")} />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          className="color-white"
        >
          {t("account.warning_update_success_text")}
        </Alert>
      </Snackbar>

      <div className="create-item-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <aside className="create-item-sidebar">
                <div className="top-collection-item mb-0">
                  <div className="collection-item-thumb">
                    <img
                      src={
                        (gamer && gamer.image_url) ||
                        "assets/img/others/user_icon.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title">{gamer && gamer.name}</h5>
                    <p className="price">
                      <a
                        target="_blank"
                        href={`${
                          process.env.REACT_APP_BLOCK_EXPLORER
                        }/address/${gamer && gamer.wallet_address}`}
                      >
                        {gamer &&
                          gamer.wallet_address &&
                          getExcerpt(gamer.wallet_address, 20)}
                      </a>
                    </p>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid color-purple">
                        {t("account.manager_selected")}
                      </li>
                      <li className="wishlist menu-color">{managerIds?.length}</li>
                    </ul>
                    <ul>
                      <li className="bid color-purple">
                        {t("account.player_selected")}
                      </li>
                      <li className="wishlist menu-color">{playerIds?.length}</li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-xl-9 col-lg-8">
              <form onSubmit={handleSubmit} className="create-item-form">
                <div className="form-grp">
                  <label htmlFor="file">
                    {t("account.upload_profile_picture")}
                  </label>
                  <input id="file" name="file" type="file" />
                </div>
                <div className="form-grp">
                  <label htmlFor="Name">{t("account.name")}</label>
                  <input
                    id="Name"
                    type="text"
                    placeholder="Enter the name"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="d-flex justify-content-between">
                <button type="submit" className="btn">
                  Update
                </button>
                <button onClick={(e) => handleDelete(e)}id="delete-btn" className="btn">
                  Delete
                </button>
                </div>
             
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPage;

import React, { useState, useEffect, useCallback } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchSquad, fetchCurrentSquadWeek } from "../../store/info";
import { convertUTCtoLocal } from "../../libs/helper";
import Swal from "sweetalert2";
import axiosIns from "../../libs/axios";
//Modal
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";

import Team from "../Team/Team";
const MyTeamPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const squad = useSelector((state) => state.info.squad);
  const gameweek = useSelector((state) => state.info.squadweek);
  const teams = useSelector((state) => state.team.teams);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [initialSquad, setInitialSquad] = useState([]);
  const [initialReserves, setInitialReserves] = useState([]);
  const [needSubmit, setNeedSubmit] = useState(false);

  const [captain, setCaptain] = useState();
  const [viceCaptain, setViceCaptain] = useState(null);

  //Modal
  const [open, setOpen] = useState(false);
  const [modalPlayer, setModalPlayer] = useState(null);

  useEffect(() => {
    if(squad?.captain){
      setCaptain(squad?.captain);
    }
  }, [squad?.captain])

  useEffect(() => {
    if(squad?.viceCaptain){
      setViceCaptain(squad?.viceCaptain);
    }
  }, [squad?.viceCaptain])


  const handleClickOpen = (player) => {
    setModalPlayer(player);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleCaptainChange = () => {
    if (viceCaptain === modalPlayer._id && captain === null) {
    
      setCaptain(modalPlayer._id);
      setViceCaptain(null);
      setOpen(false);
      return;
    }

    let temCaptain = captain;
    if (viceCaptain === modalPlayer._id) {
      setViceCaptain(temCaptain);
    }
    setCaptain(modalPlayer._id);
    setOpen(false);
  };

  const handleViceCaptainChange = () => {
    if (captain === modalPlayer._id && viceCaptain === null) {
      setViceCaptain(modalPlayer._id);
      setCaptain(null);
      setOpen(false);
      return;
    }

    let temViceCaptain = viceCaptain;
    if (captain === modalPlayer._id) {
      setCaptain(temViceCaptain);
    }
    setViceCaptain(modalPlayer._id);
    setOpen(false);
  };

  const handleSubmit = () => {
    let _initialSquad = initialSquad.map((player) => {
      return {
        player_id: player._id,
        name: player.name,
        position: player.position,
      };
    });

    let _initialReserves = initialReserves.map((player) => {
      return {
        player_id: player._id,
        name: player.name,
        position: player.position,
      };
    });

    console.log({
      initialSquad: _initialSquad,
      initialReserves: _initialReserves,
    });

    Swal.fire({
      title: "Are you sure?",
      //text: "You won't be able to revert this!",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      padding: "2em",
      //background: `#fff url(${banner && banner.image_url})`,
      backdrop: `
              rgba(0,0,123,0.4)
            `,
    }).then(async (res) => {
      if (res.isConfirmed) {
        axiosIns
          .post(
            "/squads/my-team",
            { initialSquad: _initialSquad, initialReserves: _initialReserves, captain: captain, viceCaptain: viceCaptain },
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            }
          )
          .then((response) => {
            console.log(response);
            Swal.fire({
              html: `${t(
                "myteam.success_message"
              )}`,
              //text: "You won't be able to revert this!",
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              padding: "2em",
              //background: `#fff url(${banner && banner.image_url})`,
              backdrop: `
                    rgba(0,0,123,0.4)
                  `,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const togglePlayerSelection = (player, isInitialSquad) => {
    let newInitialSquad = [...initialSquad];
    let newInitialReserves = [...initialReserves];
    let isTransfered = false;

    if (isInitialSquad) {
      // find whether the same position player and transfer = 1 is in the initialReserves
      const reservePlayerIndex = newInitialReserves.findIndex(
        (reservePlayer) =>
          reservePlayer.position === player.position &&
          reservePlayer.transfer === 1
      );

      if (reservePlayerIndex !== -1) {
        // if yes, remove that player from initialReserves and add the selected player to initialReserves
        const reservePlayer = { ...newInitialReserves[reservePlayerIndex] };
        newInitialReserves[reservePlayerIndex] = { ...player, transfer: 1 };

        // and then remove the selected player from initialSquad and add the reserve player to initialSquad
        const squadPlayerIndex = newInitialSquad.findIndex(
          (squadPlayer) => squadPlayer.id === player.id
        );
        newInitialSquad[squadPlayerIndex] = { ...reservePlayer, transfer: 0 };
        isTransfered = true;
      }
    } else {
      // find whether the same position player and transfer = 1 is in the initialSquad
      const squadPlayerIndex = newInitialSquad.findIndex(
        (squadPlayer) =>
          squadPlayer.position === player.position && squadPlayer.transfer === 1
      );

      if (squadPlayerIndex !== -1) {
        // if yes, remove that player from initialSquad and add the selected player to initialSquad
        const squadPlayer = { ...newInitialSquad[squadPlayerIndex] };
        newInitialSquad[squadPlayerIndex] = { ...player, transfer: 1 };

        // and then remove the selected player from initialReserves and add the squad player to initialReserves
        const reservePlayerIndex = newInitialReserves.findIndex(
          (reservePlayer) => reservePlayer.id === player.id
        );
        newInitialReserves[reservePlayerIndex] = {
          ...squadPlayer,
          transfer: 0,
        };
        isTransfered = true;
      }
    }

    if (player.selected === 1 || isTransfered) {
      newInitialSquad = newInitialSquad.map((squadPlayer) => {
        return { ...squadPlayer, selected: 0, transfer: 0 };
      });
      newInitialReserves = newInitialReserves.map((reservePlayer) => {
        return { ...reservePlayer, selected: 0, transfer: 0 };
      });
    } else {
      newInitialSquad = newInitialSquad.map((squadPlayer) =>
        squadPlayer.id === player.id
          ? { ...squadPlayer, selected: 1, transfer: 1 }
          : squadPlayer.position === player.position && !isInitialSquad
          ? { ...squadPlayer, selected: 1, transfer: 0 }
          : { ...squadPlayer, selected: 0, transfer: 0 }
      );

      newInitialReserves = newInitialReserves.map((reservePlayer) =>
        reservePlayer.id === player.id
          ? { ...reservePlayer, selected: 1, transfer: 1 }
          : reservePlayer.position === player.position && isInitialSquad
          ? { ...reservePlayer, selected: 1, transfer: 0 }
          : { ...reservePlayer, selected: 0, transfer: 0 }
      );
    }

    setInitialSquad(newInitialSquad);
    setInitialReserves(newInitialReserves);
    setNeedSubmit(isTransfered);
  };

  useEffect(() => {
    if (squad?.initialReserves.length > 0 && squad?.initialSquad.length > 0) {
      let teamMap = [];
      teams.forEach((team) => {
        teamMap[team.name] = team.code;
      });
      let _initialSquad = squad?.initialSquad?.map((player) => {
        let team_name = player?.statistics[0]?.team?.name;
        return {
          ...player,
          position: player?.statistics[0]?.games?.position,
          selected: 0,
          team: teamMap[team_name],
        };
      });

      let _initialReserves = squad.initialReserves.map((player) => {
        let team_name = player?.statistics[0]?.team?.name;
        return {
          ...player,
          position: player?.statistics[0]?.games?.position,
          selected: 0,
          team: teamMap[team_name],
        };
      });
      setInitialSquad(_initialSquad);
      setInitialReserves(_initialReserves);
      //console.log({_initialReserves, _initialSquad})
    }
  }, [squad, teams]);

  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  const fetchGamerSquad = useCallback(() => {
    if (accessToken) {
      dispatch(fetchSquad(accessToken));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (accessToken) {
      fetchGamerSquad();
    }
  }, [accessToken]);

  useEffect(() => {
    dispatch(fetchCurrentSquadWeek());
  }, []);

  return (
    <>
      <MainBanner slug="my-team" title={t("myteam.title")} />
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="xs"
        fullWidth={true}
        className="captain-modal"
      >
        <DialogTitle className="text-center title">
          {modalPlayer?.name}
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          {captain !== modalPlayer?._id && (
            <ListItem disableGutters>
              <ListItemButton onClick={handleCaptainChange}>
                <ListItemText primary="Make Captain" />
              </ListItemButton>
            </ListItem>
          )}
          {viceCaptain !== modalPlayer?._id && (
            <ListItem disableGutters>
              <ListItemButton onClick={handleViceCaptainChange}>
                <ListItemText primary="Make Vice Captain" />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disableGutters>
            <ListItemButton onClick={handleClose}>
              <ListItemText primary="Cancel" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      
      <div className="container mt-5 mb-5 squad-selection">
      <Alert severity="info">
             {t("myteam.info_message")}
      </Alert>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="scoreboard">
              <div className="scoreboard__title">
                {t("squadselection.gameweek")} {gameweek.gameWeek}
              </div>
              <div className="scoreboard__deadline">
                {t("squadselection.gameweek")} {gameweek.gameWeek} {t("squadselection.deadline")}:{" "}
                {convertUTCtoLocal(gameweek.startDate)}
              </div>
            </div>
            <Team
              initialSquad={initialSquad}
              initialReserves={initialReserves}
              togglePlayerSelection={togglePlayerSelection}
              hanelPlayerClick={handleClickOpen}
              captain={captain}
              viceCaptain={viceCaptain}
            />
            {(needSubmit || squad?.captain != captain || squad?.viceCaptain != viceCaptain) && (
              <div className="d-flex justify-content-center">
                <button
                  className="default_button mt-3"
                  onClick={() => handleSubmit()}
                >
                  {t("myteam.save_button")}
                </button>
              </div>
            )}
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
};

export default MyTeamPage;

import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = { testimonials: [] };

const testimonialSlice = createSlice({
    name: "testimonial",
    initialState,
    reducers: {
        fetchTestimonials(state, action) {
            state.testimonials = action.payload;
        }
    }
})

export const fetchTestimonials = (data) => {
    return (dispatch) => {
        axiosIns.get("/testimonials/list", { params: data }).then((res) => {
            dispatch(testimonialActions.fetchTestimonials(res.data.data.testimonials.docs));
        });
    };
}

export const testimonialActions = testimonialSlice.actions;

export default testimonialSlice.reducer;
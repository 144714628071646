import React from "react";
import { useTranslation } from "react-i18next";
import { getName } from "../../libs/helper";

const TeamPlayer = ({
  player,
  togglePlayerSelection,
  isInitialSquad,
  disableTransfer,
  isCaptain,
  isViceCaptin,
  hanelPlayerClick,
  isRealCaptain,
  points,
}) => {
  let realPoints = points;
  if (isRealCaptain) {
    realPoints = points * 2;
  }

  const { i18n } = useTranslation();

  return (
    <div
      className={`squad-player large ${
        player?.transfer ? "transfer" : player?.selected ? "active" : ""
      }`}
    >
      <div className="squad-player__container">
        {!disableTransfer && (
          <div
            className="squad-player__transfer-button"
            onClick={() => togglePlayerSelection(player, isInitialSquad)}
          ></div>
        )}

        {(isCaptain || isViceCaptin) && (
          <div className={`squad-player__capatin ${isRealCaptain?'real':''}`}>{isCaptain ? "C" : "V"}</div>
        )}

        <img
          className="squad-player__image"
          src={player?.image_url || player?.photo}
          alt={player?.name}
          onClick={() => hanelPlayerClick(player)}
        />
        <div className="squad-player__container--name">{getName(player,i18n.language)}</div>
        <div className={`squad-player__container--team ${isRealCaptain?'real':''}`}>{realPoints}</div>
      </div>
    </div>
  );
};
export default TeamPlayer;

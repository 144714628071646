import React from "react";
import { useTranslation } from "react-i18next";
import { getExcerpt } from "../../libs/helper";
import { Link } from "react-router-dom";
const ManagerCard = ({ manager, balance }) => {
  const { t,i18n } = useTranslation();
  const bannerStyle = {
    backgroundImage: `url(${manager && manager.image_url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginTop: "20px",
  };
  return (
    <>
      <Link
          to={`/manager/${manager && manager._id}`}
          className="magic-card-link"
        >
      <div className={`magic-card ${(balance && balance > 0)?'owned':''}`} style={bannerStyle}>

          <div>
            {manager &&
              manager.contents &&
              manager.contents.find(
                (content) => content.language === i18n.language
              ) &&
              manager.contents.find(
                (content) => content.language === i18n.language
              ).name}
          </div>
          {
            balance && balance > 0 && <div className="magic-card-balance">{t("manager.owned")}</div>
          }
       
          {manager && getExcerpt(manager.contract_address, 20)}
      </div>
      </Link>
    </>
  );
};

export default ManagerCard;

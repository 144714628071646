import { useEffect, useState } from "react";
import $ from "jquery";
import { Routes, Route } from "react-router-dom";
import Player from "./pages/Player";
import Home from "./pages/Home";
import Ranking from "./pages/Ranking";
import Standing from "./pages/Standing";
import Fixture from "./pages/Fixture";
import Faq from "./pages/Faq";
import Page from "./pages/Page"
import Manager from "./pages/Manager";
import ManagerMarketplace from "./pages/ManagerMarketplace";
import PlayerDetail from "./pages/PlayerDetail";
import Account from "./pages/Account";
import Faucet from "./pages/Faucet";
import SquadSelection from "./pages/SquadSelection";
import MyTeam from "./pages/MyTeam";
import Transfer from "./pages/Transfer";
import Point from "./pages/Point";
import PlayerOfTheWeek from "./pages/PlayerOfTheWeek";
import LeaderBoard from "./pages/LeaderBoard";

import "./assets/scss/style.scss";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { useDispatch } from "react-redux";
import { webActions } from "./store/web";
import Web3Context from "./context/Web3Context";
import SideBar from "./Components/SideBar/SideBar";
import { useSelector } from "react-redux";
import { TokenBalanceProvider } from "./context/TokenBalanceContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchSeason } from "./store/info";

function App() {
  const dispatch = useDispatch();
  const token_contract_address = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
  const management_contract_address = process.env.REACT_APP_MANAGEMENT_CONTRACT_ADDRESS;
  const multicall_contract_address = process.env.REACT_APP_MULTIACALL_CONTRACT_ADDRESS;
  useEffect(() => {
    $(".menu-trigger").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".offcanvas-wrapper,.offcanvas-overly").removeClass("active");
    });
  }, []);

  const [web3, setWeb3] = useState();
  const [provider, setProvider] = useState();
  const [managementContract, setManagementContract] = useState();
  const [tokenContract, setTokenContract] = useState();
  const [multicallContract, setMulticallContract] = useState();
  const account = useSelector((state) => state.web.account);
  const gamer = useSelector((state) => state.gamer.gamer);

  const setAccountListener = (provider) => {
    provider.on("accountsChanged", (_) => {
      //clean localStorage accessToken
      localStorage.removeItem("accessToken");
      window.location.reload()
    });
    provider.on("chainChanged", (_) => 
    {
      localStorage.removeItem("accessToken");
      window.location.reload()
    }
    );
  };

  useEffect(() => {
    const getAccount = async () => {
      const accounts = await web3.eth.getAccounts();
      dispatch(webActions.setAccount(accounts[0]));
    };
    web3 && getAccount();
  }, [web3, dispatch]);

  useEffect(() => {
    dispatch(fetchSeason(localStorage.getItem("accessToken")));
  }, [dispatch, localStorage.getItem("accessToken")]);

  useEffect(() => {
    async function fetchTokenBalance() {
      if (account && managementContract) {
        try {
          const tokenBalance = await managementContract.methods.getTokenBalance(account).call();
          dispatch(webActions.setTokenBalance(tokenBalance));
        } catch (err) {
          console.log(err);
        }
      }
    }
    fetchTokenBalance();
  }, [account, managementContract, web3, dispatch]);

  useEffect(() => {
    const loadProvider = async () => {
      const _provider = await detectEthereumProvider();
      if (_provider) {
        const _web3 = new Web3(_provider);
        const chainId = await _web3.eth.getChainId();

        const managementRes = await fetch(`/abi/Management.abi`)
        const managementAbi = await managementRes.json();
        const managementContract = await new _web3.eth.Contract(managementAbi, management_contract_address)

        const multicallRes = await fetch('/abi/Multicall.abi')
        const multicallAbi = await multicallRes.json();
        const multicallContract = await new _web3.eth.Contract(multicallAbi, multicall_contract_address)
        
        const tokenRes = await fetch(`/abi/Token.abi`)
        const tokenAbi = await tokenRes.json();
        //console.log(token_contract_address)
        const tokenContract = await new _web3.eth.Contract(tokenAbi, token_contract_address)

        setManagementContract(managementContract)
        setMulticallContract(multicallContract)
        setTokenContract(tokenContract)
        setWeb3(_web3);
        setProvider(_provider);
        dispatch(webActions.setChainId(chainId));
        setAccountListener(_provider);
      } else {
        dispatch(webActions.setIsProviderLoaded(true));
        console.log("Please install MetaMask!");
      }
    };
    loadProvider();
  }, [dispatch]);

  const theme = createTheme({
    palette: {
      mode: 'dark', // 啟用暗色主題
      primary: {
        main: '#e039fd',
      },
      secondary: {
        main: '#6345ed',
      },
    },
  });

  return (
    <div className="App">
      {/* <SideBar /> */}
      <Web3Context.Provider value={{ web3, provider, managementContract, tokenContract, multicallContract }}>
        <TokenBalanceProvider>
        <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/standing" element={<Standing />} />
          <Route path="/fixture" element={<Fixture />} />
          <Route path="/player" element={<Player />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/page/:slug" element={<Page />} />
          <Route path="/manager/:id" element={<Manager />} />
          <Route path="/player-detail/:id" element={<PlayerDetail />} />
          <Route path="/manager-marketplace" element={<ManagerMarketplace />} />
          <Route path="/account" element={<Account />} />
          <Route path="/faucet" element={<Faucet />} />
          <Route path="/squad-selection" element={<SquadSelection />} />
          <Route path="/my-team" element={<MyTeam />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/point" element={<Point />} />
          <Route path="/player-of-the-week" element={<PlayerOfTheWeek />} />
          <Route path="/leaderboard" element={<LeaderBoard />} />
        </Routes>
        </ThemeProvider>
        </TokenBalanceProvider>
      </Web3Context.Provider>
    </div>
  );
}

export default App;

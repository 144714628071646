import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import MyTeamPage from "../Components/InnerPages/MyTeamPage";

const MyTeam = () => {
    return(
        <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
            <MyTeamPage />
        </main>
        <Footer />
      </div>
    </>
    )
}

export default MyTeam;
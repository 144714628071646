import React from "react";

const LatestNews = () => {
  return (
    <section className="latest-news-area">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="section-title mb-45">
              <h2 className="title">
                Roles <img src="assets/img/icons/title_icon01.png" alt="" />
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
        <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item">
              <div className="latest-news-thumb">
              <img src="assets/img/intro/intro2.png" alt="" />
              </div>
              <div className="latest-news-content">
                <h4 className="title">
                  <a href="/" onClick={(e) => e.preventDefault()} className="text-center">
                    Manager
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item">
              <div className="latest-news-thumb">
                <img src="assets/img/intro/intro1.png" alt="" />
              </div>
              <div className="latest-news-content">
                <h4 className="title">
                  <a href="/" onClick={(e) => e.preventDefault()} className="text-center">
                    Player
                  </a>
                </h4>
              </div>
            </div>
          </div>
          
          <div className="col-xl-4 col-md-6 col-sm-9">
            <div className="latest-news-item">
              <div className="latest-news-thumb">
              <img src="assets/img/intro/intro3.png" alt="" />
              </div>
              <div className="latest-news-content">
                <h4 className="title">
                  <a href="/" onClick={(e) => e.preventDefault()} className="text-center">
                    Team
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestNews;

import React from "react";
import Banner from "../Components/Explore/Banner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
/* import { useTranslation } from "react-i18next"; */
import FanPage from "../Components/InnerPages/FaqPage";
const Faq = () => {
 /*  const { t, i18n } = useTranslation(); */
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
          <Banner title="Faqs" image="b9.png" />
          <FanPage />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Faq;

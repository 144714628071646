import React, { useState, useEffect } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import axiosIns from "../../libs/axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LeaderBoardPage = () => {
  const { t } = useTranslation();
  const [gamers, setGamers] = useState([]);
  const season = useSelector((state) => state.info.season);

    useEffect(() => {
        
        axiosIns.get("/gamer-points/season")
        .then(response => {
            {
                console.log(response.data.data);
                setGamers(response.data.data)
            }
        })
    }, [])

    //get the first 5 characters of the wallet address
    const getWalletAddress = (address) => {
      return address.substring(0, 5)+'...'   
    }
  const image_path = "assets/img/icons/icon.png"

  return (
    <>
      <MainBanner slug="leaderboard" title={t("leaderboard.title")} />
      <section className="leaderboard">
        <div className="container">
          <div className="leaders">
            <h2>Player Rankings Season {season?.year}</h2>
            <ul>
              <li>
                <div className="lead-gamers">
                    {
                        gamers?.length > 2 && (
                            <img
                    className="lead-cats__photo"
                    src={(gamers?.length > 2 && gamers[2]?.gamer?.image_url) || image_path}
                  />
                        )
                    }
                  <div className="podium pod-1">
                    <div className="ranking-lead">3</div>
                    <h4>{gamers?.length > 2 && getWalletAddress(gamers[2]?.gamer?.wallet_address)}</h4>
                    <p>{gamers?.length > 2 && gamers[2]?.totalPoints}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="lead-gamers">
                  <img
                    className="lead-cats__photo"
                    src={(gamers?.length > 0 && gamers[0]?.gamer?.image_url) || image_path}
                  />
                  <div className="podium">
                    <div className="ranking-lead">1</div>
                    <h4>{gamers?.length > 0 && getWalletAddress(gamers[0]?.gamer?.wallet_address)}</h4>
                    <p>{gamers?.length > 0 && gamers[0]?.totalPoints}</p>
                  </div>
                </div>
              </li>
              <li>
              <div className="lead-gamers">
                  <img
                    className="lead-cats__photo"
                    src={(gamers?.length > 1 && gamers[1]?.gamer?.image_url) || image_path}
                  />
                  <div className="podium  pod-3">
                    <div className="ranking-lead">2</div>
                    <h4>{gamers?.length > 1 && getWalletAddress(gamers[1]?.gamer?.wallet_address)}</h4>
                    <p>{gamers?.length > 1 && gamers[1]?.totalPoints}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="board">
            <h2>Leaderboard</h2>
            <ul>
                {
                    gamers.map((gamer, index) => (
                        <li className="gamer-item" key={`gamer_list_${index}`}>
                        <div className="gamer-item__photo">
                          <div className="ranking">{index+1}</div>
                          <img src={gamer?.gamer?.image_url || image_path } />
                        </div>
                        <div className="gamer-item__info">
                          <h4><a href= {`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${gamer?.gamer?.wallet_address}`} target="_blank">{gamer?.gamer?.wallet_address}</a></h4>
                        </div>
                        <div className="gamer-item__points">
                            <p>{gamer?.totalPoints}</p>
                        </div>
                      </li>
                    ))
                }
            
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeaderBoardPage;

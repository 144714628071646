import React from "react";

const FloatingButton = ({ title, onClick }) => {
  return (
    <div className="btn-wrap">
      <div className="floating-btn" onClick={onClick}>
        {title}
      </div>
      <div className="shadow"></div>
    </div>
  );
};

export default FloatingButton;

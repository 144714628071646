import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  /*   provider: null,
  web3: null, */
  account: null,
  chainId: null,
  isProviderLoaded: false,
  tokenBalance: 0,
  message: "",
};

const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    setProvier(state, action) {
      state.provider = action.payload;
    },
    /*     setWeb3(state, action) {
      state.web3 = action.payload;
    },*/
    setAccount(state, action) {
      state.account = action.payload;
    },
    setTokenBalance(state, action)
    {
      state.tokenBalance = action.payload;
    },
    setChainId(state, action) {
      state.chainId = action.payload;
    },
    setIsProviderLoaded(state, action) {
      state.isProviderLoaded = action.payload;
    },
    /*     setMessage(state, action) {
      const message = !state.isProviderLoaded
        ? "MessageWeb3Required"
        : state.account
        ? state.chainId != process.env.REACT_APP_CHAIN_ID
          ? `MessageNetworkIncorrect`
          : ""
        : !state.provider
        ? "MessageWalletNotDetected"
        : "MessageConnectWallet";
      state.message = message;
    }, */
  },
});

export const webActions = webSlice.actions;

export default webSlice.reducer;

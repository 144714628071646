import { configureStore } from "@reduxjs/toolkit";
import teamReducer from "./team";
import playerReducer from "./player";
import fixtureReducer from "./fixture";
import webReducer from "./web";
import standingReducer from "./standing";
import infoReducer from "./info";
import faqRuducer from "./faq";
import testimonialReducer from "./testimonial";
import managerReducer from "./manager";
import gamerReducer from "./gamer";

const store = configureStore({
  reducer: {
    info: infoReducer,
    team: teamReducer,
    player: playerReducer,
    fixture: fixtureReducer,
    web: webReducer,
    standing: standingReducer,
    faq: faqRuducer,
    testimonial: testimonialReducer,
    manager: managerReducer,
    gamer: gamerReducer,
  },
});

export default store;

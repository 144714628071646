import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";
import {getCurrentWeek} from "../libs/helper";

const initialState = { fixtures: [], gameWeeks: [], current_week_index: null, max_current_week_index: null};

const fixtureSlice = createSlice({
  name: "fixture",
  initialState,
  reducers: {
    fetchFixtures(state, action) {
      state.fixtures = action.payload;
    },
    fetchGameWeeks(state, action) {
      state.gameWeeks = action.payload;
    },
    setCurrentWeekIndex(state, action) {
      state.current_week_index = action.payload;
    },
    setMaxCurrentWeekIndex(state, action) {
      state.max_current_week_index = action.payload;
    },
    incrementCurrentWeekIndex(state) {
      state.current_week_index += 1;
    },
    decrementCurrentWeekIndex(state) {
      state.current_week_index -= 1;
    }
  },
});

export const fetchFixtures = (data) => {
  return (dispatch) => {
    axiosIns.get("/fixtures", { params: data }).then((res) => {
      dispatch(fixtureActions.fetchFixtures(res.data.data.fixtures.docs));
    });
  };
};

export const fetchGameWeekFixtures = (data) => {
  return (dispatch) => {
    axiosIns.get("/game-weeks/fixtures", { params: data }).then((res) => {
      dispatch(fixtureActions.fetchFixtures(res.data.data.fixtures));
    });
  };
}

export const fetchGameWeeks = () => {
  return (dispatch) => {
    axiosIns.get("/game-weeks/list").then((res) => {
      dispatch(fixtureActions.fetchGameWeeks(res.data.data.game_weeks));
      let currentWeek = getCurrentWeek(res.data.data.game_weeks);
      dispatch(fixtureActions.setCurrentWeekIndex(currentWeek));
      dispatch(fixtureActions.setMaxCurrentWeekIndex(currentWeek));
    });
  };
}

export const fixtureActions = fixtureSlice.actions;

export default fixtureSlice.reducer;

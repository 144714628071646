import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import SquadSelectionPage from "../Components/InnerPages/SquadSelectionPage";

const SquadSelection = () => {
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
            <SquadSelectionPage />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default SquadSelection;
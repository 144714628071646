import React, { useState, useEffect, useCallback } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Field from "../Transfer/Field";
import axiosIns from "../../libs/axios";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";

import {
  fetchManagers,
  fetchCurrentSquadWeek,
  fetchBalance,
  fetchSquad,
} from "../../store/info";
import ModalPlayer from "../Modal/ModalPlayer";
//import PlayerSelector from "../Field/PlayerSelector/PlayerSelector";
import PlayerSelector from "../Transfer/PlayerSelector/PlayerSelector";
import { convertUTCtoLocal } from "../../libs/helper";

const TransferPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialSquad = useSelector((state) => state.info.squad);
  const players = useSelector((state) => state.info.players);
  const gameweek = useSelector((state) => state.info.squadweek);
  const balance = useSelector((state) => state.info.balance);
  const positions = useSelector((state) => state.info.positions);
  const [transfers, setTransfers] = useState([]);
  const [enableTransfer, setEnableTransfer] = useState(false);

  //For modal
  const [modalPlayer, setModalPlayer] = useState({});
  const [open, setOpen] = useState(false);
  const [cardOpen, setCardOpen] = useState(false);

  const [filteredPlayers, setFilteredPlayers] = useState(players);

  const [position, setPosition] = useState("");
  const [team, setTeam] = useState("");
  const [name, setName] = useState("");
  const [cost, setCost] = useState(0);
  const [squad, setSquad] = useState([]);
  const [temSquad, setTemSquad] = useState([]);
  const [buyList, setBuyList] = useState([]);

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchManagers(accessToken));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    dispatch(fetchCurrentSquadWeek());
  }, []);

  useEffect(() => {
    let refund = squad.reduce((acc, player) => {
      if (!player?.player?.selected) {
        //console.log("unselected player", player, player?.player?.now_cost);
        acc += Number(player?.player?.now_cost);
      }
      return acc;
    }, 0);

    let fee = buyList.reduce((acc, player) => {
      if (player?.player?.selected) {
        acc += Number(player?.player?.now_cost);
      }
      return acc;
    }, 0);

    if (!refund) {
      refund = 0;
    }

    if (!fee) {
      fee = 0;
    }
    setCost(refund - fee);

    //find out the player that is not selected
    let unselectedPlayers = squad.filter((player) => !player?.player?.selected);

    let enable = true;
    let temTransfers = [];

    if (unselectedPlayers.length > 0) {
      unselectedPlayers.forEach((player) => {
        let inPlayer = buyList.find(
          (buyPlayer) => buyPlayer.index === player.index
        );

        if (!inPlayer) {
          enable = false;
        } else {
          temTransfers.push({
            out: player.player._id,
            in: inPlayer.player._id,
          });
        }
      });

      setTransfers(temTransfers);

      setEnableTransfer(enable);
    } else {
      setEnableTransfer(false);
    }
  }, [squad, buyList]);

  useEffect(() => {
    if (Number(balance) + Number(cost) < 0) {
      setEnableTransfer(false);
    }
  }, [balance, cost]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardOpen = () => {
    setCardOpen(true);
    console.log("cardOpen", cardOpen);
  };

  const handleCardClose = () => {
    setCardOpen(false);
  };

  const handleOpen = (player) => {
    setModalPlayer(player);
    setOpen(true);
  };

  const handleSetPosition = (position) => {
    setPosition(position);
  };

  //提交後更新balance
  const updateBalance = useCallback(() => {
    if (accessToken) {
      dispatch(fetchBalance(accessToken));
    }
  }, [accessToken]);

  const updateSquad = useCallback(() => {
    if (accessToken) {
      dispatch(fetchSquad(accessToken));
    }
  }, [accessToken]);

  useEffect(() => {
    if (initialSquad?.initialSquad?.length > 0) {
      let index = 0;
      let _initialSquad = initialSquad.initialSquad.map((player) => {
        index++;
        return {
          position: player.statistics[0].games.position,
          player: { ...player, selected: true },
          index: index,
        };
      });

      let _initialReserves = initialSquad.initialReserves.map((player) => {
        index++;
        return {
          position: player.statistics[0].games.position,
          player: { ...player, selected: true },
          index: index,
        };
      });
      let newSquad = [..._initialSquad, ..._initialReserves];
      // Sort newSquad based on the 'index' property
      newSquad.sort((a, b) => a.index - b.index);

      //console.log(newSquad);

      setSquad(newSquad);
      setTemSquad(newSquad);
    }
  }, [initialSquad]);

  useEffect(() => {
    let filterPlayers;
    if (position || team || name) {
      filterPlayers = players.filter((player) => {
        const playerPosition =
          player.statistics[0].games.position.toLowerCase();
        const playerTeam = player.statistics[0].team.name.toLowerCase();
        const playerName = player.name.toLowerCase();

        return (
          playerPosition.includes((position || "").toLowerCase()) &&
          playerTeam.includes((team?.name || "")?.toLowerCase()) &&
          playerName.includes((name || "")?.toLowerCase())
        );
      });
    } else {
      filterPlayers = players;
    }
    setFilteredPlayers(filterPlayers);
    /*     console.log({ players });
    console.log("filterPlayers", filterPlayers); */
  }, [players, position, team, name]);

  const handleTransferSquad = () => {
    Swal.fire({
      title: t("transfer.submit_transfer"),
      html: t("transfer.submit_transfer_content"),
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t("button.submit"),
      cancelButtonText: t("button.cancel"),
      padding: "2em",
      //background: `#fff url(${banner && banner.image_url})`,
      backdrop: `
            rgba(0,0,123,0.4)
          `,
    }).then((res) => {
      if (res.isConfirmed) {
        if (enableTransfer) {
          submitTransfer();
        }
      }
    });
  };

  const submitTransfer = useCallback(async () => {
    if (accessToken) {
      try {
        const res = await axiosIns.post(
          "/squads/transfer",
          { transfers },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (res.status === 200) {
          updateBalance();
          updateSquad();
          setBuyList([]);
          Swal.fire({
            title: t("form.success"),
            html: t("transfer.submit_success"),
            icon: "success",
            confirmButtonText: "OK",
            padding: "2em",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
                  rgba(0,0,123,0.4)
                `,
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: t("form.error"),
          html:  t("transfer.submit_fail"),
          icon: "error",
          confirmButtonText: "OK",
          padding: "2em",
          //background: `#fff url(${banner && banner.image_url})`,
          backdrop: `
                rgba(0,0,123,0.4)
              `,
        });
      }
    }
  }, [accessToken, transfers, enableTransfer]);

  const handleReset = () => {
    setSquad(temSquad);
    setBuyList([]);
  };

  const handleAutoPick = () => {

    //Calculation
    let _unselectedPlayer = [];
    let playerIds = [];
    //找出那個position 欠人
    squad.forEach((player) => {
      if (!player.player.selected) {
        _unselectedPlayer.push({
          index: player.index,
          position: player.position,
        });
      }
      playerIds.push(player.player._id);
    });

    setBuyList([]);

    let _players = players.filter((player) => !playerIds.includes(player._id));
    _players.sort(() => Math.random() - 0.5);

    //get the key and value of position
    _unselectedPlayer.forEach((player) => {
      let _position = player.position;
      //get the player from _players, add it to buyList and remove it from _players
      let _player = _players.find((player) => player.position === _position);

      if (_player) {
        setBuyList((prev) => [
          ...prev,
          {
            player: { ..._player, selected: true, transfer: true },
            index: player.index,
            position: player.position,
          },
        ]);
        _players = _players.filter((player) => player._id !== _player._id);
      }
    });
  };

  const handleAddPlayer = (playerToAdd) => {
    const position = playerToAdd.statistics[0].games.position;

    // Find out number of same position in the newBuyList
    const numberOfSamePosition =
      buyList?.filter((buyListPlayer) => buyListPlayer.position === position)
        .length + 1;

    // Find out number of same position available in the squad
    const numberOfSamePositionInSquad = squad?.filter(
      (squadPlayer) =>
        squadPlayer.position === position &&
        squadPlayer.player &&
        !squadPlayer.player.selected
    ).length;

    if (numberOfSamePosition < numberOfSamePositionInSquad) {
      return;
    }

    // Find all unselected players in the same position
    const unselectedPlayers = squad.filter((squadPlayer) => {
      let unselected =
        squadPlayer.position === playerToAdd.statistics[0].games.position &&
        squadPlayer.player &&
        !squadPlayer.player.selected;

      if (unselected) {
        // check if the squadPlayer.index is in the buyList
        let index = squadPlayer.index;

        // find if the index exist in the buyList
        let indexInBuyList = buyList?.findIndex(
          (buyListPlayer) => buyListPlayer.index === index
        );

        if (indexInBuyList !== -1) {
          unselected = false;
        }
      }

      return unselected;
    });

    // Find if there's an index in buyList for this position
    const indexInBuyList = buyList.findIndex((buyListPlayer) =>
      unselectedPlayers.find(
        (unselectedPlayer) => unselectedPlayer.index === buyListPlayer.index
      )
    );

    // If there's a free spot in the squad for this position
    if (unselectedPlayers.length > 0 && indexInBuyList === -1) {
      // Find the first unselected player in the squad for this position
      const freeSpot = unselectedPlayers[0];

      // Add the player to buyList with the index of the free spot
      const newBuyList = [
        ...buyList,
        {
          player: { ...playerToAdd, selected: true, transfer: true },
          index: freeSpot.index,
          position: freeSpot.position,
        },
      ];

      setBuyList(newBuyList);
    }
  };

  return (
    <>
      <MainBanner slug="transfer" title={t("transfer.title")} />
      <ModalPlayer open={open} handleClose={handleClose} player={modalPlayer} />
      <Dialog open={cardOpen} onClose={handleCardClose}>
        <div className="blindbox-modal" onClick={handleCardClose}>
          <div className="blindbox-modal__title">
            請期待即將推出的技能卡盲盒
          </div>
          <img src="assets/img/blindbox/blindbox.png" alt="" />
        </div>
      </Dialog>

      <div className="container mt-5 mb-5 squad-selection">
        <div className="row justify-content-center">
          <div className={`col-md-8`}>
            <div className="scoreboard">
              <div className="scoreboard__title">
                {t("squadselection.gameweek")} {gameweek.gameWeek}
              </div>
              <div className="scoreboard__deadline">
                {t("squadselection.gameweek")} {gameweek.gameWeek}{" "}
                {t("squadselection.deadline")}:{" "}
                {convertUTCtoLocal(gameweek.startDate)}
              </div>
              <div className="scoreboard__detail transfer">
                <div className="scoreboard__detail__item transfer">
                  <span>{t("transfer.free_transfers")}</span>
                  <span
                    className={`${
                      1 - buyList?.length > 0 ? "success" : "fail"
                    }`}
                  >
                    {1 - buyList?.length < 0 ? 0 : 1 - buyList?.length}
                  </span>
                  <button onClick={handleAutoPick}>
                    {t("transfer.auto_pick")}
                  </button>
                </div>
                <div className="scoreboard__detail__item transfer">
                  <span>{t("transfer.cost")}</span>
                  <span
                    className={`${
                      1 - buyList?.length < 0 ? "fail" : "success"
                    }`}
                  >
                    {1 - buyList?.length < 0 ? (1 - buyList?.length) * -4 : 0}
                  </span>
                  <button onClick={handleReset}>{t("transfer.reset")}</button>
                </div>
                <div className="scoreboard__detail__item transfer">
                  <span>{t("transfer.money_remaining")}</span>
                  <span
                    className={`${
                      Number(balance) + Number(cost) > 0 ? "success" : "fail"
                    }`}
                  >
                    {Number(balance) + Number(cost)}
                  </span>
                  <button onClick={handleCardOpen}>
                    {t("transfer.play_wildcard")}
                  </button>
                </div>
              </div>
            </div>
            <Field
              squad={squad}
              setSquad={setSquad}
              setPosition={handleSetPosition}
              buyList={buyList}
              setBuyList={setBuyList}
            />
            <div className="d-flex justify-content-center">
              <button
                className="default_button mt-3"
                disabled={!enableTransfer}
                onClick={() => handleTransferSquad()}
              >
                {t("transfer.make_transfers")}
              </button>
            </div>
          </div>
          <div className={`col-md-4`}>
            <PlayerSelector
              setPosition={setPosition}
              position={position}
              team={team}
              setTeam={setTeam}
              name={name}
              setName={setName}
              filteredPlayers={filteredPlayers}
              handleOpen={handleOpen}
              squad={squad}
              setSquad={setSquad}
              buyList={buyList}
              setBuyList={setBuyList}
              handleAddPlayer={handleAddPlayer}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferPage;

import React from 'react'
import Slider from "react-slick"
import { useTranslation } from "react-i18next";

const Testimonial = ({testimonials}) => {
  const { i18n } = useTranslation();
  const settings = {
  dots: true,
	infinite: true,
	speed: 1000,
	autoplay: false,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
			}
		},
  ]
  }
  return (
   
    <section className="testimonial--area">
    <div className="container">
      <div className="testimonial-shape-wrap">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <Slider className="testimonial-active"{...settings}>
              {
                testimonials.map((testimonial, index) => (
                  <div className="testimonial--item text-center" key={`testimonial_${index}`}>
                  <div className="testimonial-rating">
                    <img src="assets/img/others/star.png" alt="" />
                  </div>
                  <div className="testimonial--content">
                    <p>{testimonial.contents.find(content => content.language === i18n.language) && testimonial.contents.find(content => content.language === i18n.language).testimonial}</p>
                    <div className="testimonial--avatar--info">
                      <h5 className="title">{testimonial.contents.find(content => content.language === i18n.language) && testimonial.contents.find(content => content.language === i18n.language).author}</h5>
                    </div>
                  </div>
                </div>
                ))
              }           
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Testimonial
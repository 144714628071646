import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";
import Swal from "sweetalert2";

const initialState = {
    gamer:{}
}

const gamerSlice = createSlice({
    name: "gamer",
    initialState,
    reducers:{
        setGamer(state, action){
            state.gamer = action.payload;
        }
    }
});

export const fetchGamer = (accessToken) => {
    return (dispatch) => {
        axiosIns.get("/gamers",
        {
            headers: {
                Authorization: "Bearer " + accessToken
            }
        }
        ).then((res) => {
            //console.log('fetchGamer', res.data.gamer)
            dispatch(gamerActions.setGamer(res.data.gamer));
        });
    };
}

export const updateGamer = ({accessToken, data, title, text}) => {
    return (dispatch) => {
        axiosIns.post("/gamers", data, {
            headers: {
                Authorization: "Bearer " + accessToken
            }
        })
        .then(res => {
            Swal.fire({
                title: title,
                html: text,
                focusConfirm: false,
                confirmButtonText: "OK",
                padding: "2em",
                //background: `#fff url(${banner && banner.image_url})`,
                backdrop: `
                      rgba(0,0,123,0.4)
                    `,
              })
            dispatch(gamerActions.setGamer(res.data.gamer))
        })
    }
}

export const gamerActions = gamerSlice.actions;

export default gamerSlice.reducer;
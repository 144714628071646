import React, { useState, useEffect, useCallback } from "react";
import Field from "../Field/Field";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { fetchManagers, fetchCurrentGameWeek, fetchCurrentSquadWeek } from "../../store/info";
import PlayerSelector from "../Field/PlayerSelector/PlayerSelector";
import { useSelector, useDispatch } from "react-redux";
import ModalPlayer from "../Modal/ModalPlayer";
import Alert from "@mui/material/Alert";
import { convertUTCtoLocal } from "../../libs/helper";
import axiosIns from "../../libs/axios";
import Swal from "sweetalert2";

const SquadSelectionPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //Need to add two Goalkeepers, five Defenders, five Midfielders and three Forwards.
  const players = useSelector((state) => state.info.players);
  const managers = useSelector((state) => state.info.managers);
  const gameweek = useSelector((state) => state.info.squadweek);
  const [filteredPlayers, setFilteredPlayers] = useState(players);

  const [squad, setSquad] = useState([]);
  const [cost, setCost] = useState(0);
  const [numberOfPlayers, setNumberOfPlayers] = useState(0);
  const [position, setPosition] = useState("");
  const [team, setTeam] = useState("");
  const [name, setName] = useState("");
  //For modal
  const [modalPlayer, setModalPlayer] = useState({});
  const [open, setOpen] = useState(false);

  //show player list
  const [showPlayerList, setShowPlayerList] = useState(false);

  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));

  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, []);

  const handleSubmitSquad = () => {
    Swal.fire({
      title:'Submit Squal',
      html: `Your action is final and cannot be undone. Are you sure you want to submit your squad?`,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      padding: "2em",
      //background: `#fff url(${banner && banner.image_url})`,
      backdrop: `
            rgba(0,0,123,0.4)
          `,
    }).then((res) => {
      if (res.isConfirmed) {
        submitSquad()
      }

    })
  }

  const submitSquad = useCallback(
    async () => {
      if(accessToken && numberOfPlayers == 15)
      {
        let submitSquad = squad.map(player => {
          return {position: player.position, player_id: player.player._id, now_cost: player.player.now_cost}
        })

        axiosIns
        .post("/squads/", {squad: submitSquad},{
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        })
        .then((response) => {
          console.log(response)
          Swal.fire({
            title: 'Success!',
            //text: "You won't be able to revert this!",
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            padding: "2em",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
                  rgba(0,0,123,0.4)
                `,
          }).then(res => {
            if(res.isConfirmed){
              window.location.href = '/my-team';
            }
          })
        })
        .catch((error) => {
          console.log(error)
          Swal.fire({
            title: 'Fail!',
            //text: "You won't be able to revert this!",
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            padding: "2em",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
                  rgba(0,0,123,0.4)
                `,
          })
        })


      }else{
        console.log('fail to submit the squad')
      }
    },[accessToken, numberOfPlayers, squad]
  )


  useEffect(() => {
    const initialPlayers = getInitialPlayers();
    setSquad(initialPlayers);
    dispatch(fetchCurrentSquadWeek());
  }, []);

  const handleOpen = (player) => {
    setModalPlayer(player);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let filterPlayers;
    if (position || team || name) {
      filterPlayers = players.filter((player) => {
        const playerPosition =
          player.statistics[0].games.position.toLowerCase();
        const playerTeam = player.statistics[0].team.name.toLowerCase();
        const playerName = player.name.toLowerCase();

        return (
          playerPosition.includes((position || "").toLowerCase()) &&
          playerTeam.includes((team?.name || "")?.toLowerCase()) &&
          playerName.includes((name || "")?.toLowerCase())
        );
      });
    } else {
      filterPlayers = players;
    }
    setFilteredPlayers(filterPlayers);
/*     console.log({ players });
    console.log("filterPlayers", filterPlayers); */
  }, [players, position, team, name]);

    useEffect(() => {
      let count = 0;
      let tem_cost = 0;
      squad.forEach((player) => {
        if (player.player) {
          count++;
          if (player.player.now_cost) {
            tem_cost += parseInt(player.player.now_cost);
          }
        }
      });
      setNumberOfPlayers(count);
      setCost(tem_cost);
    }, [squad]);

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchManagers(accessToken));
    }
  }, [dispatch, accessToken]);

  const getInitialPlayers = () => {
    const initialPlayers = [];
    const squads = [
      { position: "Attacker", count: 3 },
      { position: "Midfielder", count: 5 },
      { position: "Defender", count: 5 },
      { position: "Goalkeeper", count: 2 },
    ];
    squads.forEach((squad) => {
      for (let i = 0; i < squad.count; i++) {
        initialPlayers.push({ position: squad.position, player: null });
      }
    });
    return initialPlayers;
  };

  const handleSetPosition = (position) => {
    setPosition(position);
    setShowPlayerList(true);
  };

  const autoPick = () => {
    const squads = [
      { position: "Goalkeeper", count: 2 },
      { position: "Defender", count: 5 },
      { position: "Midfielder", count: 5 },
      { position: "Attacker", count: 3 },
    ];
    let newSquad = [];
    squads.forEach((squad) => {
      const positionPlayers = players.filter(
        (player) =>
          player.statistics[0].games.position.toLowerCase() ===
          squad.position.toLowerCase()
      );
      for (let i = 0; i < squad.count; i++) {
        if (positionPlayers.length > 0) {
          const randomIndex = Math.floor(
            Math.random() * positionPlayers.length
          );
          const player = positionPlayers[randomIndex];
          // Remove the selected player from the positionPlayers array
          positionPlayers.splice(randomIndex, 1);
          newSquad.push({ position: squad.position, player: player });
        } else {
          newSquad.push({ position: squad.position, player: null });
        }
      }
    });
    setSquad(newSquad);
  };

  const handleReset = () => {
    const initialPlayers = getInitialPlayers();
    setSquad(initialPlayers);
  };

  return (
    <>
      <MainBanner slug="squard-selection" title={t("squadselection.title")} />
      <ModalPlayer open={open} handleClose={handleClose} player={modalPlayer} />
      <div className="container mt-5 mb-5 squad-selection">
      {managers.length >0 && (
      <Alert severity="info">
             {t("squadselection.info_message")}
      </Alert>
      )}
        <div className="row justify-content-center">
          <div className={`col-md-8 ${showPlayerList ? "" : ""}`}>
            <div className="scoreboard">
              <div className="scoreboard__title">
                {t("squadselection.gameweek")} {gameweek.gameWeek}
              </div>
              <div className="scoreboard__deadline">
                {t("squadselection.gameweek")} {gameweek.gameWeek} {t("squadselection.deadline")}:{" "}
                {convertUTCtoLocal(gameweek.startDate)}
              </div>
              <div className="scoreboard__detail">
                <div className="scoreboard__detail__item">
                  <span>{t("squadselection.player_selected")}</span>
                  <span
                    className={`${numberOfPlayers === 15 ? "success" : "fail"}`}
                  >
                    {numberOfPlayers} / 15
                  </span>
                  <button onClick={() => autoPick()}>{t("squadselection.auto_pick")}</button>
                </div>
                <div className="scoreboard__detail__item">
                  <span>{t("squadselection.money_remaining")}</span>
                  <span className={`${1000 - cost >= 0 ? "success" : "fail"}`}>
                    {1000 - cost} {process.env.REACT_APP_GAME_POINT}
                  </span>
                  <button onClick={() => handleReset()}>{t("squadselection.reset")}</button>
                </div>
              </div>
            </div>
            <Field
              squad={squad}
              setSquad={setSquad}
              setPosition={handleSetPosition}
            />
            <div className="d-flex justify-content-center">
              <button
                className="default_button mt-3"
                disabled={numberOfPlayers !== 15}
                onClick={() => handleSubmitSquad()}
              >
                {t("squadselection.enter_squad")}
              </button>
            </div>
          </div>
          <div className={`col-md-4 ${!showPlayerList ? "" : ""}`}>
            {managers.length == 0 && (
              <Alert severity="error">
                {t("squadselection.manager_warning")}
              </Alert>
            )}
            <PlayerSelector
              setPosition={setPosition}
              position={position}
              team={team}
              setTeam={setTeam}
              name={name}
              setName={setName}
              filteredPlayers={filteredPlayers}
              handleOpen={handleOpen}
              setShowPlayerList={setShowPlayerList}
              squad={squad}
              setSquad={setSquad}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SquadSelectionPage;

import React from "react";
import SquadPlayer from "./SquadPlayer/SquadPlayer";
import { useSelector } from "react-redux";

const Field = ({ squad, setSquad, setPosition, buyList, setBuyList }) => {
  const positions = useSelector((state) => state.info.positions);

  const handleRemovePlayer = (playerToRemove, index) => {
    // Find if there's an index in buyList for this position
    const indexInBuyList = buyList.findIndex(
      (buyListPlayer) => buyListPlayer.index === index
    );

    if (indexInBuyList === -1) {
      const newSquad = squad.map((squadPlayer) =>
        squadPlayer.player && squadPlayer.player.id === playerToRemove.id
          ? {
              ...squadPlayer,
              player: {
                ...squadPlayer.player,
                selected: !squadPlayer?.player?.selected,
              },
            }
          : squadPlayer
      );
      setSquad(newSquad);
    } else {
      //remove the player from buyList
      // If there's a player in buyList with the same index, remove it
      const newBuyList = [...buyList];
      newBuyList.splice(indexInBuyList, 1);
      setBuyList(newBuyList);
    }
  };

  return (
    <div className="field">
      <div className="field__container">
        <div className="field__half">
          <div className="penalty-box">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
          <div className="formation formation--home">
            {positions.map((position, index) => (
              <div className="formation__line" key={`formation__line_${index}`}>
                {squad
                  .filter(
                    (squadPlayer) =>
                      squadPlayer.position.toLowerCase() ===
                      position.toLowerCase()
                  )
                  .map((squadPlayer, index) => {
                    // Find player in buyList with the same index
                    const buyListPlayer = buyList.find(
                      (buyPlayer) => buyPlayer.index === squadPlayer.index
                    );

                    // If there's a player in buyList with the same index, use it, otherwise use the player from squad
                    const player = buyListPlayer?.player || squadPlayer.player;
                    const squad_index = squadPlayer.index;
                    return (
                      <SquadPlayer
                        key={`player_${index}`}
                        player={player}
                        setPosition={setPosition}
                        position={position}
                        index={squad_index}
                        handleRemovePlayer={handleRemovePlayer}
                      />
                    );
                  })}
              </div>
            ))}
          </div>
        </div>
        <div className="field__center-line"></div>
        <div className="field__half field__half--reverse">
          <div className="penalty-box ">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
        </div>
      </div>
    </div>
  );
};

export default Field;

import React, { useState } from "react";
import Banner from "../Components/Explore/Banner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import FixturePage from "../Components/InnerPages/FixturePage";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  fetchGameWeekFixtures,
  fetchGameWeeks,
  fixtureActions,
} from "../store/fixture";

const Fixture = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fixtures = useSelector((state) => state.fixture.fixtures);
  const gameWeeks = useSelector((state) => state.fixture.gameWeeks);
  const current_week_index = useSelector(
    (state) => state.fixture.current_week_index
  );
  const season = useSelector((state) => state.info.season);

  const incrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.incrementCurrentWeekIndex());
  };

  const decrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.decrementCurrentWeekIndex());
  };

  useEffect(() => {
    const getGameWeeks = () => {
      dispatch(fetchGameWeeks());
    };
    getGameWeeks();
  }, [dispatch]);

  useEffect(() => {
    const getFixture = () => {
      dispatch(
        fetchGameWeekFixtures({
          season: season.year,
          game_week: gameWeeks[current_week_index].gameWeek,
        })
      );
    };

    if (current_week_index != null && season.year && gameWeeks.length > 0) {
      getFixture();
    }
  }, [current_week_index, season, gameWeeks, dispatch]);

/*   useEffect(() => {
    console.log({ fixtures });
  }, [fixtures]); */

  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
          <Banner title={t("fixture.heading")} image="b5.png" />
          <FixturePage
            fixtures={fixtures}
            incrementCurrentWeekIndex={incrementCurrentWeekIndex}
            decrementCurrentWeekIndex={decrementCurrentWeekIndex}
            current_week_index={current_week_index}
            gameWeeks={gameWeeks}
          />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Fixture;

import React from "react";
import { useTranslation } from "react-i18next";

const StandingPage = ({ standings }) => {
  const { t} = useTranslation();
  return (
    <div className="activity-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="welcome-activity">
              <div className="welcome-activity-img welcome-img-two">
                <img
                  src="assets/img/others/epl.png"
                  alt=""
                  className="team-heading-logo"
                />
              </div>
              <div className="welcome-activity-content">
                <h3 className="title">WELCOME to MCTAVERSE RANKING</h3>
                <p>
                  The epl teams, ranked by points and other{" "}
                  <a href="/#">statistics.</a>
                </p>
              </div>
            </div>
            <div className="activity-table-wrap">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="nft"
                  role="tabpanel"
                  aria-labelledby="nft-tab"
                >
                  <div className="activity-table-responsive table-responsive">
                    <table className="table activity-table standing-table">
                      <thead>
                        <tr>
                          <th scope="col">{t("standing.rank")}</th>
                          <th
                            scope="col"
                            className="sticky-col background-primary "
                          >
                            {t("standing.team")}
                          </th>
                          <th scope="col">{t("standing.played")}</th>
                          <th scope="col">{t("standing.win")}</th>
                          <th scope="col">{t("standing.draw")}</th>
                          <th scope="col">{t("standing.lose")}</th>
                          <th scope="col">{t("standing.goals_for")} </th>
                          <th scope="col">{t("standing.goals_against")} </th>
                          <th scope="col">{t("standing.goal_difference")} </th>
                          <th scope="col">{t("standing.points")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {standings.map((standing, index) => (
                          <tr key={index}>
                            <td>{standing.rank}</td>
                            <th
                              scope="row"
                              className="author sticky-sm-start sticky-col"
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  className="standing-team-logo"
                                  src={standing.team.logo}
                                  alt=""
                                />{" "}
                                <a onClick={(e) => e.preventDefault()} href="/">{standing.team.name}</a>
                              </div>
                            </th>
                            <td>{standing.all.played}</td>
                            <td>{standing.all.win}</td>
                            <td>{standing.all.draw}</td>
                            <td>{standing.all.lose}</td>
                            <td>{standing.all.goals.for}</td>
                            <td>{standing.all.goals.against}</td>
                            <td>{standing.goalsDiff}</td>
                            <td>{standing.points}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandingPage;

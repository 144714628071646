import React, { useState, useEffect } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchGameWeeks, fixtureActions } from "../../store/fixture";
import { convertUTCtoLocal } from "../../libs/helper";
import axiosIns from "../../libs/axios";
import Team from "../Point/Team";

const PointPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gameWeeks = useSelector((state) => state.fixture.gameWeeks);

  const teams = useSelector((state) => state.team.teams);
  const [minGameWeek, setMinGameWeek] = useState(null);
  const [maxGameWeek, setMaxGameWeek] = useState(null);

  const current_week_index = useSelector(
    (state) => state.fixture.current_week_index
  );

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );

  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  const season = useSelector((state) => state.info.season);
  const [initialSquad, setInitialSquad] = useState([]);
  const [initialReserves, setInitialReserves] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [captain, setCaptain] = useState(null);
  const [viceCaptain, setViceCaptain] = useState(null);

  //api data
  const [statistics, setStatistics] = useState([]);
  const [realCaptain, setRealCaptain] = useState(null);

  const [dispplayTeam, setDisplayTeam] = useState(false);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [points, setPoints] = useState(0);
  const incrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.incrementCurrentWeekIndex());
  };

  const decrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.decrementCurrentWeekIndex());
  };

  useEffect(() => {
    const getGameWeeks = () => {
      dispatch(fetchGameWeeks());
    };
    getGameWeeks();
  }, [dispatch]);

  useEffect(() => {
    if (gameWeeks && accessToken && gameWeeks[current_week_index]?.gameWeek) {
      axiosIns
        .get("/gamer-points/points", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
          params: {
            gameweek: gameWeeks[current_week_index]?.gameWeek,
          },
        })
        .then((response) => {
          let { gameWeek, gamerPoints } = response.data;

          let _minGameWeek = response.data.minGameWeek;
          let _maxGameWeek = response.data.maxGameWeek;
          let _statistics = response?.data?.gamerPoints?.statistics;
          let _realCaptain = response?.data?.gamerPoints?.realCaptain;

          if (gameWeek && current_week_index + 1 != gameWeek) {
            dispatch(fixtureActions.setCurrentWeekIndex(gameWeek - 1));
          }
          setMinGameWeek(_minGameWeek);
          setMaxGameWeek(_maxGameWeek);
          setPoints(gamerPoints?.points);
          setRealCaptain(_realCaptain);
          let _statisticsMap = [];

          _statistics.forEach((statistic) => {
            _statisticsMap[statistic.player_id] = parseInt(statistic.points);
          });
          setStatistics(_statisticsMap);
        });

      axiosIns
        .get("/squads/gameweek/" + gameWeeks[current_week_index]?.gameWeek, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          let squad = response?.data?.squad;
          setTransactions(response?.data?.processDescription);
          if (squad?.captain) {
            setCaptain(squad?.captain);
          } else {
            setCaptain(null);
          }
          if (squad?.viceCaptain) {
            setViceCaptain(squad?.viceCaptain);
          } else {
            setViceCaptain(null);
          }

          if (
            squad?.initialReserves.length > 0 &&
            squad?.initialSquad.length > 0
          ) {
            let teamMap = [];
            teams.forEach((team) => {
              teamMap[team.name] = team.code;
            });
            let _initialSquad = squad?.initialSquad?.map((player) => {
              let team_name = player?.statistics[0]?.team?.name;
              return {
                ...player,
                position: player?.statistics[0]?.games?.position,
                selected: 0,
                team: teamMap[team_name],
              };
            });

            let _initialReserves = squad.initialReserves.map((player) => {
              let team_name = player?.statistics[0]?.team?.name;
              return {
                ...player,
                position: player?.statistics[0]?.games?.position,
                selected: 0,
                team: teamMap[team_name],
              };
            });
            setDisplayTeam(true);
            setInitialSquad(_initialSquad);
            setInitialReserves(_initialReserves);
            //console.log({_initialReserves, _initialSquad})
          } else {
            setDisplayTeam(false);
            setInitialSquad([]);
            setInitialReserves([]);
          }
        });
    }
  }, [gameWeeks, current_week_index, accessToken, teams]);

  return (
    <>
      <MainBanner slug="point" title={t("point.title")} />
      <section className="mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className={`col-md-8`}>
              <div className="fixture-gameweek point">
                <div className="gameweek">
                  <button
                    className={`gameweek-btn ${
                      current_week_index + 1 <= minGameWeek ? "disabled" : ""
                    }`}
                    onClick={decrementCurrentWeekIndex}
                    disabled={
                      current_week_index == 0 ||
                      current_week_index + 1 <= minGameWeek
                    }
                  >
                    Previous
                  </button>
                  <div className="fixture-gameweek__scorebox">
                    <h5>
                      GameWeek{" "}
                      {gameWeeks &&
                        current_week_index != null &&
                        gameWeeks[current_week_index].gameWeek}
                    </h5>
                  </div>
                  <button
                    className={`gameweek-btn ${
                      current_week_index + 1 >= maxGameWeek ? "disabled" : ""
                    }`}
                    onClick={incrementCurrentWeekIndex}
                    disabled={current_week_index + 1 >= maxGameWeek}
                  >
                    Next
                  </button>
                </div>

                <div className="fixture-gameweek__scorebox--box">
                  <span>Final Points</span>
                  <div className="score-text">{points}</div>
                </div>
              </div>
              <Team
                initialSquad={initialSquad}
                initialReserves={initialReserves}
                togglePlayerSelection={() => {}}
                disableTransfer={true}
                captain={captain}
                viceCaptain={viceCaptain}
                realCaptain={realCaptain}
                statistics={statistics}
              />
            </div>
            <div className="col-md-4">
              <div className="point-transaction">
                <h5>Transaction</h5>
                <div className="point-transaction__wrapper">
                  <div className="point-transaction__content">
                    {transactions?.map((transaction, index) => (
                      <div className="point-transaction__box" key={`transaction_${index}`}>
                        <div className="point-transaction__box--photo">
                          <img
                            src={transaction?.image_url || transaction?.photo}
                            alt=""
                          />
                        </div>

                        <div className="point-transaction__box--info">
                          <span>{convertUTCtoLocal(transaction?.date)}</span>
                          <span>{transaction?.name}</span>
                          <span>{transaction?.action}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PointPage;

import React from "react";
import { useTranslation } from "react-i18next";
import { getName } from "../../../libs/helper";

const SquadPlayer = ({ player , position, setPosition, handleRemovePlayer, index}) => {
    //to lower case position
  const buttonTitle = position.toLowerCase() == 'attacker' ? 'Add Attacker' : position.toLowerCase() == 'midfielder' ? 'Add Midfielder' : position.toLowerCase() == 'defender' ? 'Add Defender' : 'Add Goalkeeper';
  const photo = player?.selected && (player?.image_url || player?.photo) ? player?.image_url || player.photo : '/assets/img/logo/logo-epl.png';
  const { i18n } = useTranslation();
  return (
    <div className={`squad-player ${player?.transfer?'transfer':''}`}>
      {
        player && (
          <button className="squad-player__cancel-button" onClick={() => handleRemovePlayer(player, index)}></button>
        )
      }
      <div className="squad-player__container">
        <img src={photo} alt="" />
        <button onClick={() => setPosition(position)} className="add-button">{(player?.selected && getName(player,i18n.language)) || buttonTitle}</button>
        <div className="squad-player__container--cost">
              {player?.selected && player?.now_cost || ''}
        </div>
      </div>
    </div>
  );
};

export default SquadPlayer;
import React, { useEffect, useState, useContext, useMemo } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useSelector, useDispatch } from "react-redux";
import { fetchManagers } from "../../store/manager";
import ManagerCard from "../IndexOne/ManagerCard";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import Web3Context from "../../context/Web3Context";

const ManagerMarketplacePage = () => {
  const dispatch = useDispatch();
  const managers = useSelector((state) => state.manager.managers);
  const { multicallContract, web3 } = useContext(Web3Context);
  const account = useSelector((state) => state.web.account);
  const { t } = useTranslation();

  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const [perPage] = useState(12);
  const totalPages = useSelector((state) => state.manager.totalPages);
  const [managerBalance, setManagerBalance] = useState([]);


  const createManagerNftContract = useMemo(async () => {
    if (!web3) return null;
    const managerNftRes = await fetch('/abi/ManagerNft.abi');
    const managerNftAbi = await managerNftRes.json();
    return (manager) => {
      if (!manager) return null;
      try {
        return new web3.eth.Contract(managerNftAbi, manager.contract_address);
      } catch (err) {
        console.error(`Failed to create ManagerNft contract: ${err}`);
        return null;
      }
    };
  }, [web3]);

  useEffect(() => {
    async function fetchData() {
      if (web3 && managers.length > 0 && account) {
        // 創建所有 ManagerNft 合約實例
        const managerNftContracts = await Promise.all(managers.map(async (manager) => {
          const createContract = await createManagerNftContract;
          return createContract(manager);
        }));
        // 創建調用對象陣列
        const calls = managerNftContracts.map((managerNftContract, i) => {
          return {
            target: managerNftContract.options.address,
            callData: managerNftContract.methods.balanceOf(account).encodeABI(),
            // 將 manager 信息保存到調用對象中，以便在處理結果時使用
            manager_id: managers[i]._id
          };
        });
        // 聚合調用
        try {
          let balances = [];
          const results = await multicallContract.methods.tryAggregate(false, calls).call();
            for (let i = 0; i < results.length; i++) {
              const result = results[i];
              if (result.success) {
                const balance = web3.eth.abi.decodeParameter("uint256", result.returnData);
                //console.log(`Manager ${calls[i].manager_id} has balance ${balance}`);
                balances[calls[i].manager_id] = balance;
              } else {
                console.log(`Failed to call balanceOf for manager ${calls[i].manager_id}`);
                balances[calls[i].manager_id] = 0;
              }
            }
            setManagerBalance(balances);
        } catch (err) {
          console.error(`Failed to aggregate calls: ${err}`);
        }
      }
    }

    if(web3 && managers && account && multicallContract)
    {
      fetchData();
    }

    
  }, [web3, managers, account, createManagerNftContract, multicallContract]);

  useEffect(() => {
    let params = {
      sortBy: 'contents.name',
      sortDesc: false,
      page: page,
      perPage: perPage,
      q: q
    };
    dispatch(fetchManagers(params));
  }, [dispatch, page, q, perPage]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const handleQChange = (e) => {
    setQ(e.target.value);
  }

  return (
    <>
      <MainBanner
        slug="manager-marketplace"
        title={t("manager_market.heading")}
      />
      <div className="category-area">
        <div className="container">
          <div className="row">
            <div className="col-12 header-form">
              <form action="#">
                <button><i className="flaticon-search" /></button>
                <input type="text" placeholder={t("manager_market.search_manager")} value={q} onChange={handleQChange} />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="browse-category-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            {managers.map((manager) => (
              <div className="col-xl-3 col-lg-4" key={manager._id}>
                <ManagerCard manager={manager} balance={managerBalance && managerBalance[manager._id]} />
              </div>
            ))}

            <div className="col-12 mt-10" style={{marginTop:`50px`}}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                activeClassName="page-selected"
                pageClassName="page-item"
                className="pagination-area"
                forcePage={page - 1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerMarketplacePage;
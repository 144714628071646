import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import TeamPlayer from "./TeamPlayer";

const Team = ({initialSquad, initialReserves, togglePlayerSelection, disableTransfer, hanelPlayerClick, captain, viceCaptain}) => {
const positions = useSelector((state) => state.info.positions);

return (
    <div className="field">
      <div className="field__container">
        <div className="field__half myteam">
          <div className="penalty-box">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
          <div className="formation formation--home">
            {positions.map((position, index) => (
              <div className="formation__line myteam" key={`formation__line_${index}`}>
                {initialSquad
                  .filter((player) => player?.position?.toLowerCase() === position?.toLowerCase())
                  .map((player, index) => (
                    <TeamPlayer
                      key={`player_${index}`}
                      player={player}
                      isInitialSquad={true}
                      togglePlayerSelection={togglePlayerSelection}
                      disableTransfer={disableTransfer}
                      hanelPlayerClick={hanelPlayerClick}
                      isCaptain={captain === player?._id}
                      isViceCaptin={viceCaptain === player?._id}
                    />
                  ))}
              </div>
            ))}
            <div className="formation__line myteam reverse">
                {initialReserves
                  .map((player, index) => (
                    <TeamPlayer
                      key={`player_reserves_${index}`}
                      player={player}
                      isInitialSquad={false}
                      togglePlayerSelection={togglePlayerSelection}
                      disableTransfer={disableTransfer}
                      hanelPlayerClick={() => {}}
                      isCaptain={captain === player?._id}
                      isViceCaptin={viceCaptain === player?._id}
                    />
                  ))}
              </div>
          </div>
         
        </div>
        <div className="field__center-line"></div>
        <div className="field__half field__half--reverse myteam">
          <div className="penalty-box ">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
        </div>
      </div>
    </div>
)
}

export default Team;
const uuid = require("uuid");
const { DateTime } = require('luxon');

export function getName(player, language)
{
   return player?.names?.find(name => name.language === language)?.name || player?.name
}

export function calculateWeeksUntilDate(date) {
  const ONE_WEEK_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;

  const today = new Date();
  const todayUTC = new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
  );

  const inputDate = new Date(date);
  const inputDateUTC = new Date(
    Date.UTC(
      inputDate.getUTCFullYear(),
      inputDate.getUTCMonth(),
      inputDate.getUTCDate()
    )
  );

  const differenceInMilliseconds = todayUTC.getTime() - inputDateUTC.getTime();

  const differenceInWeeks = differenceInMilliseconds / ONE_WEEK_IN_MILLISECONDS;

  let week = Math.floor(differenceInWeeks);

  return week > 38 ? 38 : week;
}

//timestamp to local datetime
export function timestampToLocalDatetime(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours();
  const minute = date.getMinutes();
  //const second = date.getSeconds();

  return `${year}-${month}-${day} ${hour}:${minute}`;
}

export function getExcerpt(content, length) {
  return content.slice(0, length) + "...";
}


export function generateNonce() {
  const uuidValue = uuid.v4();
  // Remove hyphens from the UUID value and convert to lowercase
  const uuidWithoutHyphens = uuidValue.replace(/-/g, "").toLowerCase();
  // Generate 4 random bytes and convert to hexadecimal
  const randomBytes = new Array(4)
    .fill(0)
    .map(() => Math.floor(Math.random() * 256))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  // Combine the UUID without hyphens and the random bytes to form the nonce
  const nonce = uuidWithoutHyphens + randomBytes;
  return nonce;
}

//get current week index
export function getCurrentWeek(game_weeks) {
  const today = DateTime.local().startOf("day").toJSDate();

  if (today < new Date(game_weeks[0].startDate)) {
    return 0;
  }

  if (today > new Date(game_weeks[game_weeks.length - 1].endDate)) {
    return game_weeks.length - 1;
  }

  for (let i = 0; i < game_weeks.length; i++) {
    if (
      today >= new Date(game_weeks[i].startDate) &&
      today <= new Date(game_weeks[i].endDate)
    ) {
      return i;
    }
  }
}

export function convertUTCtoLocal(utcDateString) {
  const date = DateTime.fromISO(utcDateString);
  return date.toFormat('yyyy-LL-dd HH:mm');
}
import React from "react";
import Banner from "../Components/Explore/Banner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import StandingPage from "../Components/InnerPages/StandingPage";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchStandings } from "../store/standing";
import { useEffect } from "react";

const Standing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const standings = useSelector((state) => state.standing.standings);
  const season = useSelector((state) => state.info.season);
  useEffect(() => {
    if(season.year)
    {
      dispatch(fetchStandings(season.year));
    }
    
  }, [dispatch, season.year]);

  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
          <Banner title={t("standing.heading")} image="b1.png" />
          <StandingPage standings={standings} />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Standing;

import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {getName} from "../../libs/helper";
function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fi-sr-arrow-left"></i>
    </button>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="fi-sr-arrow-right"></i>
    </button>
  );
}

const TopPlayer = ({ players }) => {
  const { t, i18n } = useTranslation();
  //console.log(players);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, 
    autoplaySpeed: 5000, 
    responsive: [
      {
        breakpoint: 768, // screen size 768px or less
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true, 
           autoplaySpeed: 5000, 
           arrows: false
        },
      },
    ],
  };
  return (
    <section className="top-collection-area">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="section-title mb-40">
              <h2 className="title">
                {t("top_player.heading")}
                <img src="assets/img/icons/title_icon01.png" alt="" />
              </h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className="top-collection-nav" />
          </div>
        </div>
        {players.length > 0 && (   <Slider className="row top-collection-active" {...settings}>
          {players.map((player, index) => (
            <div className="col-xl-3" key={`player_${index}`}>
              <div className="top-collection-item">
                <div className="collection-item-top">
                  <ul>
                    <li className="avatar">
                      <a onClick={(e) => e.preventDefault()} href="/" className="thumb">
                        <img src={player.statistics[0].team.logo} alt="" />
                      </a>{" "}
                      <a onClick={(e) => e.preventDefault()} href="/" className="name">
                        {player.statistics[0].team.name}
                      </a>
                    </li>
               
                  </ul>
                </div>
                <div className="collection-item-thumb">
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <img src={player?.image_url || player.photo} alt="" />
                  </a>
                </div>
                <div className="collection-item-content">
                  <h5 className="title">
                    <a onClick={(e) => e.preventDefault()} href="/">{getName(player,i18n.language)}</a>{" "}
                    <span className="price">
                      {player.goals_scored}
                    </span>
                  </h5>
                </div>
                <div className="collection-item-bottom">
                  <ul>
                   {/*  <li className="bid">
                      <a onClick={(e) => e.preventDefault()} href="/" className="btn">
                        Select
                      </a>
                    </li> */}
                    <li className="color-menu">
                      <a onClick={(e) => e.preventDefault()} href="/" className="color-menu"> <i className="fa fa-laugh-squint mr-1" />{player?.statistics[0]?.games?.rating && player?.statistics[0]?.games?.rating.toFixed(2)}</a>
                    </li>
                    <li >
                      <a onClick={(e) => e.preventDefault()} href="/" className="color-menu"><i className="fa fa-coins mr-1" />{player?.now_cost}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Slider>)}
     
      </div>
    </section>
  );
};

export default TopPlayer;

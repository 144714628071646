import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import { useParams } from "react-router-dom";
import axiosIns from "../libs/axios";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { i18n } = useTranslation();
  const { slug } = useParams();

  const [page, setPage] = useState({});
  const [content, setContent] = useState({});

  useEffect(() => {
    axiosIns.get(`/pages/${slug}`).then((response) => {
      console.log(response.data.data);
      setPage(response.data.data);
    });
  }, [slug]);

  useEffect(() => {
    const languageContent = page.contents?.find(
      (content) => content.language === i18n.language
    );
    setContent(languageContent);
  }, [page, i18n.language]);

  const bannerStyle = {
    backgroundImage: `url(${page.image_url})`,
  };

  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <section className="breadcrumb-area breadcrumb-bg" style={bannerStyle}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="breadcrumb-content text-center">
                  <h3 className="title">{content?.title}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="page-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div  dangerouslySetInnerHTML={{ __html: content?.content }}></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Page;

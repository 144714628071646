import React from "react";
import SquadPlayer from "./SquadPlayer/SquadPlayer";
import {useSelector} from "react-redux";

const Field = ({ squad, setSquad, setPosition }) => {
  const positions = useSelector((state) => state.info.positions);

  const handleRemovePlayer = (playerToRemove) => {
    const newSquad = squad.map(squadPlayer =>
      squadPlayer.player && squadPlayer.player.id === playerToRemove.id
        ? { ...squadPlayer, player: null }
        : squadPlayer
    );
    setSquad(newSquad);
  };

  return (
    <div className="field">
      <div className="field__container">
        <div className="field__half">
          <div className="penalty-box">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
          <div className="formation formation--home">
            {positions.map((position, index) => (
              <div className="formation__line" key={`formation__line_${index}`}>
                {squad
                  .filter((player) => player.position.toLowerCase() === position.toLowerCase())
                  .map((player, index) => (
                    <SquadPlayer
                      key={`player_${index}`}
                      player={player.player}
                      setPosition={setPosition}
                      position={position}
                      handleRemovePlayer={handleRemovePlayer}
                    />
                  ))}
              </div>
            ))}
          </div>
        </div>
        <div className="field__center-line"></div>
        <div className="field__half field__half--reverse">
          <div className="penalty-box ">
            <div className="penalty-box__container">
              <div className="penalty-box__infield"></div>
              <div className="penalty-box__circle"></div>
            </div>
          </div>
          <div className="field__corner field__corner--right"></div>
          <div className="field__corner field__corner--left"></div>
        </div>
      </div>
    </div>
  );
};

export default Field;

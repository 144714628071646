import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";
import { getExcerpt } from "../../libs/helper";

const MobileMenu = ({
  handleChangeLanguage,
  i18n,
  flag,
  connectWallet,
  web,
  t,
  gamer,
  accessToken,
}) => {
  return (
    <div className="mobile-menu">
      <nav className="menu-box">
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <div className="nav-logo">
          <a href="/#">
            <img src="assets/img/logo/logo-epl.png" alt="" title="true" />
          </a>
        </div>
        <div className="menu-outer">
          {/* Here Menu Will Come Automatically Via Javascript / Same Menu as in Header */}
        </div>
        {
          false && gamer.wallet_address && (
            <div className="language-select">
          <ul className="navigation">
            <li
              className="language-selector"
              onClick={() => handleChangeLanguage("en")}
            > <a  href="/account">
              {getExcerpt(gamer.wallet_address,15)}
              </a>
            </li>
            <li
            >
              <a  href="/account">
                Account Center
              </a>
            </li>
            <li><Link to="/faucet">Faucet</Link></li>
          </ul>
        </div>
          )
        }

        <div className="language-select">
          <ul className="navigation">
          <li
              className="language-selector"
              onClick={() => handleChangeLanguage("zh")}
            >
              <a onClick={(e) => e.preventDefault()} href="/#">
                <ReactCountryFlag
                  className="country-flag mr-1"
                  countryCode="hk"
                  svg
                />{" "}
                繁體中文
              </a>
            </li>
            <li
              className="language-selector"
              onClick={() => handleChangeLanguage("cn")}
            >
              <a onClick={(e) => e.preventDefault()} href="/#">
                <ReactCountryFlag
                  className="country-flag mr-1"
                  countryCode="hk"
                  svg
                />{" "}
                簡体中文
              </a>
            </li>
            <li
              className="language-selector"
              onClick={() => handleChangeLanguage("en")}
            >
              <a onClick={(e) => e.preventDefault()} href="/#">
                <ReactCountryFlag
                  className="country-flag mr-1"
                  countryCode="us"
                  svg
                />{" "}
                English
              </a>
            </li>
            
          </ul>
        </div>
        {!gamer.wallet_address && (
          <div className="header-action">
            <ul>
              <li className="header-btn">
                <a className="btn" onClick={connectWallet} href="/">
                  {!web.account
                    ? t("header.wallet_connect")
                    : t("header.wallet_connected")}
                </a>
              </li>
            </ul>
          </div>
        )}

        <div className="social-links">
          <ul className="clearfix">
            <li>
              <a href="/#">
                <span className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="/#">
                <span className="fab fa-facebook-square" />
              </a>
            </li>
            <li>
              <a href="/#">
                <span className="fab fa-pinterest-p" />
              </a>
            </li>
            <li>
              <a href="/#">
                <span className="fab fa-instagram" />
              </a>
            </li>
            <li>
              <a href="/#">
                <span className="fab fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MobileMenu;

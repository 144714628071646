import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import TransferPage from "../Components/InnerPages/TransferPage";
const Transfer = () => {
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
            <TransferPage />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Transfer;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const pages = useSelector((state) => state.info.pages);

  return (
    <footer>
      <div className="footer-top-wrap">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-9">
              <div className="footer-widget">
                <div className="footer-logo mb-25">
                  <a href="/index">
                    <img
                      src="/assets/img/logo/logo-epl.png"
                      alt=""
                      className="footer-logo-img"
                    />
                  </a>
                </div>
                <p>{t("footer.intro")}</p>
                <ul className="footer-social">
                  <li>
                    <a href="/#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget">
                <h4 className="fw-title">MCTAVERSE</h4>
                <ul className="fw-links">
                  <li>
                    <Link to="/standing"> {t("header.ranking")}</Link>
                  </li>
                  <li>
                    <Link to="/player"> {t("header.player")}</Link>
                  </li>
                  <li>
                    <Link to="/fixture"> {t("header.fixture")}</Link>
                  </li>
                  <li>
                    <Link to="/player-of-the-week"> {t("header.playeroftheweek")}</Link>
                  </li>
                  <li>
                    <Link to="/leaderboard"> {t("header.leaderboard")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget">
                <h4 className="fw-title">More</h4>
                <ul className="fw-links">
                  <li>
                    <Link to="/faq">Faq</Link>
                  </li>

                  {
                    pages.map(page => (
                      <li key={page.id}>
                        <Link to={`/page/${page.slug}`}>{page?.contents?.find(content => content.language === i18n.language)?.title}</Link>
                        </li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget">
                <h4 className="fw-title">Subscribe Us</h4>
                <form action="#" className="newsletter-form">
                  <input type="email" placeholder="info@mctaverse.com" />
                  <button type="submit">
                    <i className="flaticon-small-rocket-ship-silhouette" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-text">
                <p>
                  All rights reserved © 2023 by <a href="/#">MCTAVERSE</a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="copyright-link-list">
              {
                    pages.map(page => (
                      <li key={page.id}>
                        <Link to={`/page/${page.slug}`}>{page?.contents?.find(content => content.language === i18n.language)?.title}</Link>
                        </li>
                    ))
                  }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

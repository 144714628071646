import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const MainBanner = ({ slug, title, subtitle }) => {
    const banners = useSelector((state) => state.info.banners);
    const [banner, setBanner] = useState(null);

    useEffect(() => {
        const banner = banners.find((banner) => banner.slug === slug);
        setBanner(banner);
    }, [slug, banners])

    const bannerStyle = {
        backgroundImage: `url(${banner && banner.image_url})`,
    };

    return(
        <section className="breadcrumb-area breadcrumb-bg" style={bannerStyle}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="breadcrumb-content text-center">
                <h3 className="title">{title}</h3>
                {
                  subtitle && (
                    <h3 className="mt-3">{subtitle}</h3>
                  )
                } 
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default MainBanner;
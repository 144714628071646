import React from "react";
import Banner from "../Components/Explore/Banner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import AccountPage from "../Components/InnerPages/AccountPage";

const Account = () => {
  return (
    <>
      <div className="main-content">
        <Header />
        <main>
            <AccountPage />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Account;

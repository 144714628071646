import React from "react";
import Banner from "./Banner";
import LatestNews from "./LatestNews";
import Testimonial from "./Testimonial";
import TopPlayer from "./TopPlayer";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeams } from "../../store/team";
import { fetchStandings } from "../../store/standing";
import { fetchPlayers } from "../../store/player";
import { fetchTestimonials } from "../../store/testimonial";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const IndexOne = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  //const teams = useSelector((state) => state.team.teams);
  const standings = useSelector((state) => state.standing.standings);
  const players = useSelector((state) => state.player.players);
  const testimonials = useSelector((state) => state.testimonial.testimonials);

  const banner = {
    heading: t("main_page.heading"),
    sub_heading: t("main_page.sub_heading"),
  };

  useEffect(() => {
    dispatch(fetchTeams());
    dispatch(fetchStandings());
    dispatch(fetchTestimonials())
    dispatch(
      fetchPlayers({ sortBy: "goals_scored", sortDesc: true })
    );
  }, [dispatch]);

  return (
    <main>
      <Banner banner={banner} standings={standings} key={i18n.language} />
      <TopPlayer players={players} />
      <LatestNews />
      <Testimonial testimonials={testimonials} />
    </main>
  );
};

export default IndexOne;

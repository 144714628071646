import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = { teams: [] };

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeams(state, action) {
      state.teams = action.payload;
    },
  },
});

export const fetchTeams = (season) => {
  return (dispatch) => {
    axiosIns
      .get("/teams", {
        params: { season: season || process.env.REACT_APP_SEASON, perPage: 100 },
      })
      .then((res) => {
        dispatch(teamActions.setTeams(res.data.data.teams.docs));
      });
  };
};

export const teamActions = teamSlice.actions;

export default teamSlice.reducer;

import React, { useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeams } from "../../../store/team";
import { useTranslation } from "react-i18next";
import {getName} from "../../../libs/helper";
const PlayerSelector = ({
  setPosition,
  position,
  team,
  setTeam,
  name,
  setName,
  filteredPlayers,
  handleOpen,
  squad,
  setSquad,
  buyList,
  setBuyList,
  handleAddPlayer
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const positions = useSelector((state) => state.info.positions);
  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const season = useSelector((state) => state.info.season);
  const teams = useSelector((state) => state.team.teams);

  useEffect(() => {
    if (season.year) {
      dispatch(fetchTeams(season.year));
    }
  }, [dispatch, season]);

  const handleClear = () => {
    setPosition(null);
  };

  const resetFilter = () => {
    setPosition("");
    setTeam("");
    setName("");
  };

  

  return (
    <div className="player-selector">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("player.position")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={position}
          label="Position"
          displayEmpty
          onChange={handlePositionChange}
          key={position}
        >
          {positions.map((position, index) => (
            <MenuItem key={`position_${index}`} value={position}>
              {position}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="team">{t("player.team")}</InputLabel>
        <Select
          labelId="team"
          id="demo-simple-select"
          value={team}
          label="Team"
          displayEmpty
          onChange={handleTeamChange}
        >
          {teams.map((team, index) => (
            <MenuItem key={`team_${index}`} value={team}>
              {team.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{t("player.name")}</InputLabel>
        <OutlinedInput label="Name" value={name} onChange={handleNameChange} />
      </FormControl>
      <button className="reset-button" onClick={() => resetFilter()}>
      {t("player.reset")}
      </button>
      <div className="filtered-player-container">
        {filteredPlayers?.map((player) => {
          // Check if player is already in the squad
          const isInSquad =
            squad?.some(
              (squadPlayer) =>
                squadPlayer.player && squadPlayer.player.id === player.id
            ) ||
            buyList?.some(
              (buyListPlayer) => buyListPlayer.player.id === player.id
            );

          return (
            <div className="player-selector__player" key={player.name} style={isInSquad ? {display: 'none'} : {}}>
              <div className="player-selector__player-image">
                <img
                  src={player?.image_url || player?.photo}
                  alt={player?.name}
                  className="playerImage"
                  onClick={() => handleOpen(player)}
                />
              </div>

              <div className="player-selector__player-info">
                <span>{getName(player, i18n.language)}</span>
                <span>{player?.statistics[0]?.team?.name}</span>
              </div>
              <div className="player-selector__player-price">
                <span>
                  {player?.now_cost ? "£" : ""}
                  {player?.now_cost}
                </span>
                {!isInSquad && (
                  <span
                    className="add-button"
                    onClick={() => handleAddPlayer(player)}
                  >
                    Add
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PlayerSelector;
import React from "react";
/* import { useTranslation } from "react-i18next"; */
import { timestampToLocalDatetime, convertUTCtoLocal } from "../../libs/helper";
const FixturePage = ({ fixtures, gameWeeks, current_week_index,  decrementCurrentWeekIndex, incrementCurrentWeekIndex }) => {
 /*  const { t, i18n } = useTranslation(); */
  return (
   
    <section className="fixture-page mt-5">
      <div className="container">
      <div className="fixture-gameweek">
          <button className="gameweek-btn" onClick={decrementCurrentWeekIndex} disabled={current_week_index== 0}>
            Previous
          </button>
          <h5>GameWeek {gameWeeks && current_week_index != null && gameWeeks[current_week_index].gameWeek} <br />
          {gameWeeks && current_week_index != null && convertUTCtoLocal(gameWeeks[current_week_index].startDate)}
          </h5>
          <button className="gameweek-btn" onClick={incrementCurrentWeekIndex} disabled={current_week_index== gameWeeks.length - 1}>
            Next
          </button>
      </div>
        <div className="row justify-content-center">
          {fixtures && fixtures.map((fixture, index) => (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={index}>
              <div className="top-collection-item">
                <div className="collection-item-top">
                  <ul>
                    <li className="avatar">
                      <a  onClick={(e) => e.preventDefault()} href="/" className="name">
                        {timestampToLocalDatetime(fixture.fixture.timestamp)}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="collection-item-content">
                  <h5 className="title">
                    <div>
                      <img
                        src={fixture.teams.home.logo}
                        alt=""
                        className="thumb"
                      />
                      <span className="mr-1 color-menu">
                        {fixture.teams.home.name}
                      </span>
                    </div>
                    <span className="price">{fixture.goals.home}</span>
                  </h5>
                </div>

                <div className="collection-item-content mt-2">
                  <h5 className="title">
                    <div>
                      <img
                        src={fixture.teams.away.logo}
                        alt=""
                        className="thumb"
                      />
                      <span className="mr-1 color-menu">
                        {fixture.teams.away.name}
                      </span>
                    </div>
                    <span className="price">{fixture.goals.away}</span>
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FixturePage;

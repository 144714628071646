import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import LeaderBoardPage from "../Components/InnerPages/LeaderBoardPage";

const LeaderBoard = () => {

    return (
        <>
        <SideBar />
        <div className="main-content">
          <Header />
          <main>
            <LeaderBoardPage />
          </main>
          <Footer />
        </div>
      </>
    )


}

export default LeaderBoard;
import React, { useEffect, useCallback, useContext, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import "./Header.css";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { useSelector, useDispatch } from "react-redux";
import Web3Context from "../../context/Web3Context";
import { fetchSeason, fetchManagers } from "../../store/info";
import { fetchGamer } from "../../store/gamer";
import MobileMenu from "./MobileMenu";
import Swal from "sweetalert2";
import { generateNonce } from "./../../libs/helper";
import axiosIns from "../../libs/axios";
import { getExcerpt } from "../../libs/helper";
import ModalPage from "../Modal/ModalPage";

const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [flag, setFlag] = React.useState("us");

  //Modal
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState({});
  const [content, setContent] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async (slug) => {
    const response = await axiosIns.get(`/pages/${slug}`);
    setPage(response.data.data);
    setOpen(true);
  };

  useEffect(() => {
    const languageContent = page.contents?.find(
      (content) => content.language === i18n.language
    );
    setContent(languageContent);
  }, [page, i18n.language]);

  useEffect(() => {
    if (i18n.language === "en") {
      setFlag("us");
    } else if (i18n.language === "zh") {
      setFlag("hk");
    } else if (i18n.language === "cn") {
      setFlag("cn");
    }
  }, [i18n.language]);

  /*   useEffect(() => {
    dispatch(fetchSeason());
  }, [dispatch]); */

  const { web3, provider } = useContext(Web3Context);
  const handleChangeLanguage = (language) => {
    // e.preventDefault();
    $("body").removeClass("mobile-menu-visible");
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
  };

  const web = useSelector((state) => state.web);

  const squad = useSelector((state) => state.info.squad);

  const gamer = useSelector((state) => state.gamer.gamer);

  const connectWallet = useCallback(
    (e) => {
      e.preventDefault();
      if (web.account && provider) {
        provider.enable();
      } else {
        provider.request({ method: "eth_requestAccounts" });
      }
    },
    [web.account, provider]
  );

  useEffect(() => {
    function handleClick(event) {
      // Check if the clicked element is the one you're interested in
      if (event.target.matches(".color-purple")) {
        event.stopPropagation(); // Stop the event from bubbling up
        // Get the slug from the element's data attribute and call handleOpen
        const slug = event.target.dataset.slug;
        handleOpen(slug);
      }
    }

    document.addEventListener("click", handleClick); // Add the new handler

    return () => {
      // Remove the event handler when the component is unmounted
      // or before the useEffect is run again
      document.removeEventListener("click", handleClick);
    };
  }, [web.account, web.chainId, web3]);

  /*   useEffect(() => {
    console.log({ account: web.account, wallet_address: gamer.wallet_address });
  }, [web.account, gamer.wallet_address]); */

  useEffect(() => {
    async function login() {
      Swal.fire({
        title: t("header.warning_connect_title"),
        html: `${t(
          "header.warning_connect_text1"
        )} <span class="color-purple open-modal" data-slug="terms-of-service">${t(
          "header.terms_of_service"
        )}</span> ${t(
          "header.and"
        )} <span class="color-purple open-modal" data-slug="privacy-terms">${t(
          "header.privacy_policy"
        )}</span>`,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Accept and sign!",
        cancelButtonText: "Cancel",
        padding: "2em",
        //background: `#fff url(${banner && banner.image_url})`,
        backdrop: `
              rgba(0,0,123,0.4)
            `,
      }).then(async (res) => {
        if (res.isConfirmed) {
          const randomNonce = generateNonce();
          const randomString =
            "Welcome to MCTAVERSE\n\nPlease click to sign in and accept the MCTAVERSE Terms of Service.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n" +
            web.account +
            "\n\nNonece:\n" +
            randomNonce;
          const signature = await web3.eth.personal.sign(
            randomString,
            web.account
          );
          //console.log({randomString, signature})
          axiosIns
            .post("/gamers/login", { randomString, signature })
            .then((response) => {
              console.log({ response });
              localStorage.setItem("accessToken", response.data.token);
              dispatch(fetchGamer(localStorage.getItem("accessToken")));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
    if (
      web.account &&
      web3 &&
      !localStorage.getItem("accessToken") &&
      parseInt(web.chainId) == parseInt(process.env.REACT_APP_CHAIN_ID)
    ) {
      login();
    }
  }, [web.account, web.chainId, web3]);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(fetchGamer(localStorage.getItem("accessToken")));
      //dispatch(fetchManagers(localStorage.getItem("accessToken")));
    }
    setAccessToken(localStorage.getItem("accessToken"));
  }, [localStorage.getItem("accessToken")]);

  useEffect(() => {
    async function checkChainId() {
      if (provider && web.chainId && web3) {
        if (
          parseInt(process.env.REACT_APP_CHAIN_ID) !== parseInt(web.chainId)
        ) {
          const chainId = web3.utils.toHex(process.env.REACT_APP_CHAIN_ID);
          const chainParams = {
            chainId: chainId,
            chainName: process.env.REACT_APP_CHAIN_NAME, // Replace with your chain name
            rpcUrls: [process.env.REACT_APP_RPC_ENDPOINT], // Replace with your RPC endpoint(s)
            nativeCurrency: {
              name: process.env.REACT_APP_CURRENCY_NAME, // Replace with your token name
              symbol: process.env.REACT_APP_CURRENCY_SYMBOL, // Replace with your token symbol
              decimals: 18,
            },
            blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER], // Replace with your block explorer URL(s)
          };
          try {
            console.log(process.env.REACT_APP_CHAIN_ID, web.chainId);
            Swal.fire({
              title: t("header.warning_switch_network_title"),
              html:
                t("header.warning_switch_network_html1") +
                process.env.REACT_APP_CHAIN_NAME +
                t("header.warning_switch_network_html2"),
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: t("header.warning_switch_network_button"),
              cancelButtonText: "Cancel",
              padding: "2em",
              //background: `#fff url(${banner && banner.image_url})`,
              backdrop: `
                    rgba(0,0,123,0.4)
                  `,
            }).then(async (res) => {
              await provider.request({
                method: "wallet_addEthereumChain",
                params: [chainParams],
              });
            });
            /*  await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainId }]
              }); */
          } catch (error) {
            //console.error(error);
          }
        }
      }
    }
    checkChainId();
  }, [web.chainId, provider, web3]);

  useEffect(() => {
    // SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(
        ".menu-area .navigation li.menu-item-has-children .dropdown-btn"
      ).remove();
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    // Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      // Clear the old menu content first.
      $(".mobile-menu .menu-box .menu-outer").empty();

      $(".menu-area .push-menu")
        .clone()
        .appendTo(".mobile-menu .menu-box .menu-outer");
      // Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "touchstart",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").on("touchend", function () {
        $("body").removeClass("mobile-menu-visible");
      });

      // Menu Toggle Btn
      $(".mobile-nav-toggler").on("touchend", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, [accessToken, squad, web.account]);

  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
    /*=============================================
	=     Menu sticky & Scroll to top      =
=============================================*/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-top-fixed").removeClass("header-fixed-position");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-top-fixed").addClass("header-fixed-position");
      }
    });

    /*=============================================
	=    		 Scroll Up  	         =
=============================================*/
    if ($(".scroll-to-target").length) {
      $(".scroll-to-target").on("click", function () {
        var target = $(this).attr("data-target");
        // animate
        $("html, body").animate(
          {
            scrollTop: $(target).offset().top,
          },
          1000
        );
      });
    }
  }, [squad]);
  return (
    <header>
      <ModalPage open={open} handleClose={handleClose} content={content} />
      <div id="sticky-header" className="menu-area ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap main-menu">
                <nav className="menu-nav">
                  <div className="logo">
                    <a href="/#">
                      {/* <img src="assets/img/logo/naftmak.svg" alt="" /> */}
                      <span className="menu-title">MCTAVERSE</span>
                    </a>
                  </div>
                  <div className="navbar-wrap push-menu main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li className="menu-item-has-children">
                        <a href="/#">{t("header.fixture_info")}</a>
                        <ul className="submenu">
                          <li>
                            <Link to="/fixture"> {t("header.fixture")}</Link>
                          </li>
                          <li>
                            <Link to="/standing"> {t("header.ranking")}</Link>
                          </li>
                          <li>
                            <Link to="/player-of-the-week">
                              {" "}
                              {t("header.playeroftheweek")}
                            </Link>
                          </li>
                          <li>
                            <Link to="/leaderboard">
                              {" "}
                              {t("header.leaderboard")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/player"> {t("header.player")}</Link>
                      </li>
                      <li>
                        <Link to="/manager-marketplace">
                          {" "}
                          {t("header.managermarketplace")}
                        </Link>
                      </li>
                      {!squad && web.account && (
                        <li>
                          <Link to="/squad-selection">
                            {t("header.squad_selection")}
                          </Link>
                        </li>
                      )}
                      {squad && web.account && (
                        <>
                          <li>
                            <Link to="/point">{t("header.point")}</Link>
                          </li>
                          <li>
                            <Link to="/my-team">{t("header.my_team")}</Link>
                          </li>
                          <li>
                            <Link to="/transfer">{t("header.transfer")}</Link>
                          </li>
                        </>
                      )}
                      {gamer.wallet_address && web.account && (
                        <li className="menu-item-has-children  d-md-block">
                          <Link to="/account">
                            {gamer.wallet_address &&
                              getExcerpt(gamer.wallet_address, 10)}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link to="/account">Account Center</Link>
                            </li>
                            <li>
                              <Link to="/faucet">Faucet</Link>
                            </li>
                          </ul>
                        </li>
                      )}
                      <li className="menu-item-has-children d-md-block d-none">
                        <Link to="#">
                          <ReactCountryFlag
                            className="country-flag mr-1"
                            countryCode={flag}
                            svg
                          />
                          {i18n.language === "en"
                            ? "English"
                            : i18n.language === "zh"
                            ? "繁體中文"
                            : "簡体中文"}{" "}
                        </Link>
                        <ul className="submenu">
                          <li
                            className="language-selector"
                            onClick={() => handleChangeLanguage("zh")}
                          >
                            <a onClick={(e) => e.preventDefault()} href="/">
                              <ReactCountryFlag
                                className="country-flag mr-1"
                                countryCode="hk"
                                svg
                              />{" "}
                              繁體中文
                            </a>
                          </li>
                          <li
                            className="language-selector"
                            onClick={() => handleChangeLanguage("cn")}
                          >
                            <a onClick={(e) => e.preventDefault()} href="/">
                              <ReactCountryFlag
                                className="country-flag mr-1"
                                countryCode="cn"
                                svg
                              />{" "}
                              簡体中文
                            </a>
                          </li>
                          <li
                            className="language-selector"
                            onClick={() => handleChangeLanguage("en")}
                          >
                            <a onClick={(e) => e.preventDefault()} href="/">
                              <ReactCountryFlag
                                className="country-flag mr-1"
                                countryCode="us"
                                svg
                              />{" "}
                              English
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  {(!gamer.wallet_address || !web.account) && (
                    <div className="header-action d-none d-md-block">
                      <ul>
                        <li className="header-btn">
                          <a className="btn" onClick={connectWallet} href="/">
                            {!web.account
                              ? t("header.wallet_connect")
                              : t("header.wallet_connected")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu
        handleChangeLanguage={handleChangeLanguage}
        connectWallet={connectWallet}
        web={web}
        t={t}
        flag={flag}
        i18n={i18n}
        gamer={gamer}
        accessToken={accessToken}
      />
    </header>
  );
};

export default Header;

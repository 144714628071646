import React, { useCallback, useContext, useState } from "react";
import Footer from "../Components/Footer/Footer";
import { useSelector } from "react-redux";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import { useTranslation } from "react-i18next";
import Web3Context from "./../context/Web3Context";
import { TokenBalanceContext } from "../context/TokenBalanceContext";
import Swal from "sweetalert2";
import { CircleSpinnerOverlay } from "react-spinner-overlay";

const Faucet = () => {
  const { t } = useTranslation();

  const { web3 } = useContext(Web3Context);
  const { tokenBalance, tokenSymbol, refetchTokenBalance } =
    useContext(TokenBalanceContext);
  const web = useSelector((state) => state.web);
  const [loading, setLoading] = useState(false);

  const handleFaucet = useCallback(async () => {

    try {
      // Load the Faucet contract ABI
      const faucetRes = await fetch("/abi/Faucet.abi");
      const faucetAbi = await faucetRes.json();

      // Create a contract instance
      const faucetContract = new web3.eth.Contract(
        faucetAbi,
        process.env.REACT_APP_FAUCET_CONTRACT_ADDRESS
      );

      // Get the current account
      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];
      console.log({ accounts });

      // Call the dispense function
      const amount = web3.utils.toWei("10", "ether");
      const tx = await faucetContract.methods
        .dispense(amount)
        .send({
          from: currentAccount,
        })
        .on("transactionHash", function (hash) {
          setLoading(true);
          console.log("transactionHash", hash);
        })
        .on("receipt", function (receipt) {
          console.log({receipt})
          Swal.fire({
            title: `${t("faucet.warning_request_success")}`,
            html: `${t("faucet.warning_request_text1")} <a target="_blank" href="${process.env.REACT_APP_BLOCK_EXPLORER}/tx/${receipt.transactionHash}">${receipt.transactionHash}</a>`,
            confirmButtonText: `OK`,
            width: 600,
            padding: "2em",
            color: "#716add",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
              rgba(0,0,123,0.4)
            `,
          });
          refetchTokenBalance();
          setLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  }, [web3]);

  return (
    <>
      {/* <SideBar /> */}

      <div className="main-content">
        <Header />
        <main>
          <CircleSpinnerOverlay
            loading={loading}
            overlayColor="rgba(0,0,123,0.4)"
            color="#716add"
            size="50"
          />
          <div className="faucet">
            <div className="faucet-wrapper">
              <img
                className="faucet-logo"
                src="assets/img/logo/logo-epl.png"
                alt=""
                title="true"
              />
              <h3>{t("faucet.title")}</h3>
              <h4>
                {t("faucet.current_balance")}: {tokenBalance} {tokenSymbol}
              </h4>
              <button className="epl-btn" onClick={handleFaucet}>
                {t("faucet.submitbutton")}
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Faucet;

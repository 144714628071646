import React from "react";
import { useTranslation } from "react-i18next";

const TopTeam = ({ standings }) => {
  const { t } = useTranslation();
  return (
    <div className="top-seller-area">
      <div className="container">
        <div className="top-seller-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb-40">
                <h2 className="title">
                  {t("team.top_team")}
                  <img src="assets/img/icons/title_icon01.png" alt="" />
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {standings
              .filter((item) => item.rank <= 4)
              .map((standing, index) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                  <div className="top-seller-item">
                    <div className="top-seller-img">
                      <img src={standing.team.logo} alt="" />
                    </div>
                    <div className="top-seller-content">
                      <h5 className="title">
                        <a onClick={(e) => e.preventDefault()} href="/">{standing.team.name}</a>
                      </h5>
                      <p>
                        Rank: <span>{standing.rank}</span>
                      </p>
                      <p>
                        Points: <span>{standing.points}</span>
                      </p>
                      {/*   <ul className="icon">
                        <li>
                          <a href="/collections ">
                            <i className="fi-sr-pharmacy" />
                          </a>
                        </li>
                        <li>
                          <a href="/login-register ">
                            <i className="fi-sr-share" />
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopTeam;

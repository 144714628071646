import React, { useState, useContext, useMemo } from "react";
import { fetchPlayers } from "../../store/player";
import { fetchTeams } from "../../store/team";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import ModalPlayer from "../Modal/ModalPlayer";
import Web3Context from "../../context/Web3Context";
import ManagerCard from "../IndexOne/ManagerCard";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {getName} from "../../libs/helper";
//import { Link } from "react-router-dom";
//carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PlayerPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [position, setPosition] = useState("");
  const [teamId, setTeamId] = useState("");
  const [name, setName] = useState("");
  const [managers, setManagers] = useState([]);
  const players = useSelector((state) => state.player.players);
  const positions = useSelector((state) => state.info.positions);
  const totalPages = useSelector((state) => state.player.totalPages);
  const teams = useSelector((state) => state.team.teams);
  const season = useSelector((state) => state.info.season);
  const account = useSelector((state) => state.web.account);
  const [page, setPage] = useState(1);
  const [perPage] = useState(12);
  const [filter, setFilter] = useState("goals_scored");
  const [modalPlayer, setModalPlayer] = useState({}); // [player, setPlayer
  const [managerBalance, setManagerBalance] = useState([]);
  const { multicallContract, web3 } = useContext(Web3Context);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (player) => {
    setModalPlayer(player);
    setOpen(true);
  };

  const handlePageClick = (event) => {
    //console.log("event", event, event.selected);
    setPage(event.selected + 1);
  };

  useEffect(() => {
    if (season.year) {
      dispatch(fetchTeams(season.year));
    }
  }, [dispatch, season]);

  useEffect(() => {
    let params = {
      sortBy: filter,
      sortDesc: true,
      page: page,
      perPage: perPage,
    };
    if (position !== "") {
      params.position = position;
    }

    if (name !== "") {
      params.q = name;
    }

    if (teamId !== "") {
      params.team_id = teamId;
    }

    params.is_active = true;

    dispatch(fetchPlayers(params));
    setManagers([]);
    setSelectedPlayer(null);
  }, [position, page, filter, name, teamId, dispatch, perPage]);

  useEffect(() => {
    setPage(1);
  }, [position]);

  const updateFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  //Carousel
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const showManagers = (managers, index) => {
    setManagers(managers);
    setSelectedPlayer(index);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeamId(event.target.value);
  };

  return (
    <>
      <ModalPlayer open={open} handleClose={handleClose} player={modalPlayer} />
      <div className="category-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="category-list">
                <li
                  className={`${filter === "goals_scored" ? "active" : ""}`}
                  onClick={() => updateFilter("goals_scored")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-futbol mr-1" /> {t("player.goals")}
                  </a>
                </li>
                <li
                  className={`${
                    filter === "statistics.games.rating" ? "active" : ""
                  }`}
                  onClick={() => updateFilter("statistics.games.rating")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-star mr-1" /> {t("player.rating")}
                  </a>
                </li>
                <li
                  className={`${
                    filter === "statistics.goals.saves" ? "active" : ""
                  }`}
                  onClick={() => updateFilter("statistics.goals.saves")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-shield-alt mr-1" /> {t("player.saves")}
                  </a>
                </li>

                <li
                  className={`${
                    filter === "statistics.goals.assists" ? "active" : ""
                  }`}
                  onClick={() => updateFilter("statistics.goals.assists")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-hands-helping mr-1" />{" "}
                    {t("player.assists")}
                  </a>
                </li>
                <li
                  className={`${
                    filter === "statistics.passes.total" ? "active" : ""
                  }`}
                  onClick={() => updateFilter("statistics.passes.total")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-running mr-1" /> {t("player.passes")}
                  </a>
                </li>
                <li
                  className={`${
                    filter === "statistics.tackles.total" ? "active" : ""
                  }`}
                  onClick={() => updateFilter("statistics.tackles.total")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-laugh-squint mr-1" />{" "}
                    {t("player.tackles")}
                  </a>
                </li>

                <li
                  className={`${filter === "now_cost" ? "active" : ""}`}
                  onClick={() => updateFilter("now_cost")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    <i className="fa fa-coins mr-1" /> {t("player.price")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="browse-category-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <aside className="player-filter mb-5">
                <div className="row">
                  <div className="input-wrapper col-md-4 col-12">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("player.position")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={position}
                        label="Position"
                        displayEmpty
                        onChange={handlePositionChange}
                        key={position}
                      >
                        <MenuItem value={null}>
                          <em>{t("player.none")}</em>
                        </MenuItem>
                        {positions.map((position, index) => (
                          <MenuItem key={`position_${index}`} value={position}>
                            {position}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="input-wrapper col-md-4 col-12">
                    <FormControl fullWidth>
                      <InputLabel id="team">{t("player.team")}</InputLabel>
                      <Select
                        labelId="team"
                        id="demo-simple-select"
                        value={teamId}
                        label="Team"
                        displayEmpty
                        onChange={handleTeamChange}
                      >
                          <MenuItem value={null}>
                          <em>{t("player.none")}</em>
                        </MenuItem>
                        {teams.map((team, index) => (
                          <MenuItem key={`team_${index}`} value={team.id}>
                            {team.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="input-wrapper col-md-4 col-12">
                    <FormControl fullWidth>
                      <InputLabel>{t("player.name")}</InputLabel>
                      <OutlinedInput
                        label="Name"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </FormControl>
                  </div>
                </div>
              </aside>
            </div>
            {managers?.length > 0 && (
              <div className="col-xl-3 col-lg-4">
                <aside className="browse-category-sidebar">
                  <h5 className="color-menu mb-1">Managers</h5>
                  {managers?.map((manager, index) => (
                    <ManagerCard
                      manager={manager.manager_id}
                      balance={managerBalance && managerBalance[manager._id]}
                      key={`manager_${index}`}
                      className=""
                    />
                  ))}
                </aside>
              </div>
            )}
            <div
              className={
                managers?.length > 0
                  ? "col-xl-9 col-lg-8"
                  : "col-xl-12 col-lg-12"
              }
            >
              <div className="row justify-content-center">
                <h5 className="color-menu mb-1">Players</h5>
                {players.map((player, index) => (
                  <div
                    className={`${
                      managers?.length > 0 ? "col-xl-4" : "col-xl-3"
                    } col-md-6 col-sm-6`}
                    key={index}
                    onClick={() => showManagers(player?.managerIds, index)}
                  >
                    <div
                      className={`player-card ${
                        player?.managerIds?.length > 0 ? "selectable" : ""
                      } ${selectedPlayer === index ? "selected" : ""}`}
                    >
                      <div className="collection-item-top">
                        <ul>
                          <li className="avatar">
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="thumb"
                            >
                              <img
                                src={player.statistics[0].team.logo}
                                alt=""
                              />
                            </a>{" "}
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="name"
                            >
                              {player.statistics[0].team.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="collection-item-thumb">
                        <a onClick={(e) => e.preventDefault()} href="/">
                          <img src={player?.image_url || player.photo} alt="" />
                        </a>
                      </div>
                      <div className="collection-item-content">
                        <h5 className="title">
                          <a onClick={(e) => e.preventDefault()} href="/">
                            {getName(player, i18n.language)}
                          </a>{" "}
                          <span className="price">
                            {player.statistics[0].games.position}
                          </span>
                        </h5>

                        <h6 className="title">
                          <a
                            onClick={(e) => e.preventDefault()}
                            href="/"
                            className="color-menu"
                          >
                            {filter === "statistics.goals.saves"
                              ? `${t("player.saves")}`
                              : `${t("player.goals")}`}
                          </a>{" "}
                          <span className="price">
                            {filter === "statistics.goals.saves"
                              ? player.statistics[0].goals.saves
                              : player.statistics[0].goals.total}
                          </span>
                        </h6>

                        <h6 className="title">
                          <a
                            onClick={(e) => e.preventDefault()}
                            href="/"
                            className="color-menu"
                          >
                            {t("player.assists")}
                          </a>{" "}
                          <span className="price">
                            {player.statistics[0].goals.assists}
                          </span>
                        </h6>

                        <h6 className="title">
                          <a
                            onClick={(e) => e.preventDefault()}
                            href="/"
                            className="color-menu"
                          >
                            {t("player.passes")}
                          </a>{" "}
                          <span className="price">
                            {player.statistics[0].passes.total}
                          </span>
                        </h6>
                      </div>
                      <div className="collection-item-bottom">
                        <ul>
                          {/*  <li
                            className="bid"
                            onClick={() => handleOpen(player)}
                          >
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="btn"
                            >
                              Select
                            </a>
                          </li> */}
                          <li className="color-menu">
                            <i className="fa fa-star mr-1" />
                            <a onClick={(e) => e.preventDefault()} href="/">
                              {player?.statistics[0]?.games?.rating?.toFixed(2)}
                            </a>
                          </li>

                          <li className="color-menu">
                            <i className="fa fa-coins mr-1" />
                            <a onClick={(e) => e.preventDefault()} href="/">
                              {player?.now_cost || "NA"}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={totalPages}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                activeClassName="page-selected"
                pageClassName="page-item"
                className="pagination-area mt-5"
                forcePage={page - 1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerPage;

import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = {
  managers: [],
  totalPages: 0,
  page: 0,
  limit: 12,
  totalDocs: 0,
  offset: 0,
};

const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    fetchManagers(state, action) {
      state.managers = action.payload;
    },
    setPaginate(state, action) {
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.totalDocs = action.payload.totalDocs;
      state.offset = action.payload.offset;
    },
  },
});

export const fetchManagers = (data) => {
  return (dispatch) => {
    axiosIns.get("/managers/list", { params: data }).then((res) => {
      dispatch(managerActions.fetchManagers(res.data.data.docs));
      let paginate = {
        totalPages: res.data.data.totalPages,
        page: res.data.data.page,
        limit: res.data.data.limit,
        totalDocs: res.data.data.totalDocs,
        offset: res.data.data.offset,
      }
      dispatch(managerActions.setPaginate(paginate));
    });
  };
};

export const managerActions = managerSlice.actions;

export default managerSlice.reducer;

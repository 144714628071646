import React from "react";
import Banner from "../Components/Explore/Banner";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import PlayerPage from "../Components/InnerPages/PlayerPage";
import { useTranslation } from "react-i18next";

const Player = () => {
  const { t } = useTranslation();
  return (
    <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
          <Banner title={t("player.heading")} image="b2.png"/>
          <PlayerPage />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Player;

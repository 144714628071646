import React, { useState, useEffect } from "react";
import MainBanner from "../IndexOne/MainBanner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchGameWeeks, fixtureActions } from "../../store/fixture";
import {getName} from "../../libs/helper";
import axiosIns from "../../libs/axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PlayerOfTheWeekPage = () => {
  const { t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const gameWeeks = useSelector((state) => state.fixture.gameWeeks);
  const teams = useSelector((state) => state.team.teams);
  const current_week_index = useSelector(
    (state) => state.fixture.current_week_index
  );
  const max_current_week_index = useSelector(
    (state) => state.fixture.max_current_week_index
  );

  const [topPlayers, setTopPlayers] = useState([]);

  const incrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.incrementCurrentWeekIndex());
  };

  const decrementCurrentWeekIndex = () => {
    dispatch(fixtureActions.decrementCurrentWeekIndex());
  };

  useEffect(() => {
    const getGameWeeks = () => {
      dispatch(fetchGameWeeks());
    };
    getGameWeeks();
  }, [dispatch]);

  useEffect(() => {
    if (gameWeeks && gameWeeks[current_week_index]?.gameWeek && teams) {
      axiosIns
        .get("/players/player-of-the-week", {
          params: { gameweek: gameWeeks[current_week_index]?.gameWeek },
        })
        .then((response) => {
          let teamMap = [];
          teams.forEach((team) => {
            teamMap[team.name] = team;
          });
          let _topPlayers = response?.data?.topPlayers?.map((player) => {
            let team_name = player?.player_id?.statistics[0]?.team?.name;

            return {
              ...player?.player_id,
              points: player.points,
              team: teamMap[team_name],
            };
          });
          console.log(_topPlayers);
          setTopPlayers(_topPlayers);
        });
    }
  }, [gameWeeks, current_week_index, teams]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, 
    autoplaySpeed: 5000, 
    responsive: [
      {
        breakpoint: 768, // screen size 768px or less
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoplay: true, 
           autoplaySpeed: 5000, 
           arrows: false,
        },
      },
    ],
  };

  const getNumber = (n) => {
    if(n == 1)
    {
      return "st"
    }
    else if(n == 2){
      return "nd"
    }else if(n == 3){
      return "rd"
    }else{
      return "th"
    }
  }

  return (
    <>
      <MainBanner
        slug="player-of-the-week"
        title={t("player_of_the_week.title")}
      />
      <section className="mt-5 top_player">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="fixture-gameweek point">
                <div className="gameweek">
                  <button
                    className="gameweek-btn"
                    onClick={decrementCurrentWeekIndex}
                    disabled={current_week_index == 0}
                  >
                    Previous
                  </button>
                  <div className="fixture-gameweek__scorebox">
                    <h5>
                      GameWeek{" "}
                      {gameWeeks &&
                        current_week_index != null &&
                        gameWeeks[current_week_index].gameWeek}
                    </h5>
                  </div>
                  <button
                    className={`gameweek-btn ${
                      current_week_index == max_current_week_index && `disabled`
                    }`}
                    onClick={incrementCurrentWeekIndex}
                    disabled={current_week_index == max_current_week_index}
                  >
                    Next
                  </button>
                </div>
              </div>
              {topPlayers.length > 0 && (      
              <Slider {...settings}>
                {topPlayers.map((player, index) => (
                  <div className="top_player__wrapper" key={index}>
                    <div id="card">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 267.3 427.3"
                      >
                        <clipPath id="svgPath">
                          <path
                            fill="#000"
                            d="M265.3 53.9a33.3 33.3 0 0 1-17.8-5.5 32 32 0 0 1-13.7-22.9c-.2-1.1-.4-2.3-.4-3.4 0-1.3-1-1.5-1.8-1.9a163 163 0 0 0-31-11.6A257.3 257.3 0 0 0 133.7 0a254.9 254.9 0 0 0-67.1 8.7 170 170 0 0 0-31 11.6c-.8.4-1.8.6-1.8 1.9 0 1.1-.2 2.3-.4 3.4a32.4 32.4 0 0 1-13.7 22.9A33.8 33.8 0 0 1 2 53.9c-1.5.1-2.1.4-2 2v293.9c0 3.3 0 6.6.4 9.9a22 22 0 0 0 7.9 14.4c3.8 3.2 8.3 5.3 13 6.8 12.4 3.9 24.8 7.5 37.2 11.5a388.7 388.7 0 0 1 50 19.4 88.7 88.7 0 0 1 25 15.5v.1-.1c7.2-7 16.1-11.3 25-15.5a427 427 0 0 1 50-19.4l37.2-11.5c4.7-1.5 9.1-3.5 13-6.8 4.5-3.8 7.2-8.5 7.9-14.4.4-3.3.4-6.6.4-9.9V231.6 60.5v-4.6c.4-1.6-.3-1.9-1.7-2z"
                          />
                        </clipPath>
                      </svg>
                      <div id="card-inner">
                        <div id="card-top">
                          <div className="info">
                            <div className="value">{index + 1}</div>
                            <div className="position">{getNumber(index+1)}</div>
                            <div className="team">
                              <div
                                style={{
                                  backgroundImage: `url(${player?.team?.logo})`,
                                }}
                              ></div>
                            </div>
                          
                          </div>

                          <div
                            className="image"
                            style={{ backgroundImage: `url(${player?.image_url || player?.photo})` }}
                          ></div>
                          <div className="backfont">{player?.team?.code}</div>
                        </div>
                        <div id="card-bottom">
                          <div className="name">{getName(player, i18n.language)}</div>
                          <div className="position">{player?.statistics[0]?.games?.position}</div>
                          <div class="stats">
                            <div>
                              <ul>
                                <li>
                                  <span>Score</span>
                                </li>
                                <li>
                                  <span>Cost</span>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <ul>
                                <li>
                                  <span>{player?.points}</span>
                                </li>
                                <li>
                                  <span>{player?.now_cost}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              )}

              <div className="row"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlayerOfTheWeekPage;

import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import PlayerOfTheWeekPage from "../Components/InnerPages/PlayerOfTheWeekPage";


const PlayerOfTheWeek = () => {

    return (
        <>
        <SideBar />
        <div className="main-content">
          <Header />
          <main>
            <PlayerOfTheWeekPage />
          </main>
          <Footer />
        </div>
      </>
    )


}

export default PlayerOfTheWeek;
import React, {useState, useEffect} from "react";
import TopTeam from "./TopTeam";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Banner = ({ banner, standings }) => {
  const { t } = useTranslation();
  //const banners = useSelector((state) => state.info.banners);
  //const [banner, setBanner] = useState(null);
  const banners = useSelector((state) => state.info.banners);
  const [homeBanner, setBanner] = useState(null);
  const [mobileBanner, setMobileBanner] = useState(null);



  useEffect(() => {
    let _homeBanner = banners?.find(banner => banner.slug === 'home-banner')?.image_url || '../img/banner/banner_h2.png';
    let _mobileBanner = banners?.find(banner => banner.slug === 'mobile-banner')?.image_url || '../img/banner/banner_v2.png';

    setBanner(_homeBanner);
    setMobileBanner(_mobileBanner);

  }, [banners])

    // Determine the banner based on the window width
    const bannerStyle = window.innerWidth <= 600 ? { backgroundImage: `url(${mobileBanner}) !important` } : { backgroundImage: `url(${homeBanner}) !important` };

  return (
    <>
      <div className="banner-bg" style={bannerStyle}>
        <div className="banner-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="banner-content">
                  <h2 className="title">{banner.heading}</h2>
                  <p>{banner.sub_heading}</p>
                  <a href="/" className="banner-btn">
                    {t("banner.letstart")}
                    <i className="fi-sr-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* top-seller-area */}
        {standings.length >= 4 && <TopTeam standings={standings} />}
        {/* top-seller-area-end */}
      </div>
    </>
  );
};

export default Banner;

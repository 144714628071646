import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import axiosIns from "../libs/axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainBanner from "../Components/IndexOne/MainBanner";
import ManagerPage from "../Components/InnerPages/ManagerPage";

const Manager = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [manager, setManager] = useState({});
  const [players, setPlayers] = useState([])
  const [content, setContent] = useState({});
  const [token, setToken] = useState(0);


  useEffect(() => {
    axiosIns.get(`/managers?id=${id}`).then((response) => {
      //console.log(response.data.data);
      setManager(response.data.data);
      setPlayers(response.data.players);
    });
    axiosIns.get(`/contracts/managers/exchange-rate?id=${id}`).then((response) => {
      setToken(response.data.data.exchangeRate);
    })
  }, [id]);

  useEffect(() => {
    const languageContent = manager.contents?.find(
      (content) => content.language === i18n.language
    );
    setContent(languageContent);
  }, [manager, i18n.language]);

  return (
    <>
      <SideBar />

      <div className="main-content">
        <Header />
        <main>  
            <ManagerPage manager={manager} content={content} players={players} token={token}/>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Manager;

import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SideBar from "../Components/IndexOne/SideBar";
import axiosIns from "../libs/axios";
import { useParams } from "react-router-dom";
import MainBanner from "../Components/IndexOne/MainBanner";
import PlayerDetailPage from "../Components/InnerPages/PlayerDetailPage";
import { useTranslation } from "react-i18next";
import {getName} from "../libs/helper"
const PlayerDetail = () => {
  const { i18n } = useTranslation();
    const { id } = useParams();
    const [player, setPlayer] = useState({});
    const [managers, setManagers] = useState([])

    useEffect(() => {
      axiosIns.get(`/players/${id}`).then((response) => {
        console.log(response.data.data);
        setPlayer(response.data.data);
        setManagers(response.data.managers);
      })
    }, [id])

    return (
        <>
      <SideBar />
      <div className="main-content">
        <Header />
        <main>
          <MainBanner
            slug="player-detail"
            title={(player && getName(player, i18n.language)) || "Player Detail"}
          />
          <PlayerDetailPage player={player} managers={managers}/>
        </main>
        <Footer />
      </div>
    </>
    )


}

export default PlayerDetail;
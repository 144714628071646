import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const ModalPage = ({ open, handleClose, content }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    maxWidth: 1024,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1D1933",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflow: "auto",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-player-wrapper"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="w-100">
              <Typography variant="h5" component="h2" mt={1} mb={1}>
                {content?.title}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: content?.content }}></div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalPage;
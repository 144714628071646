import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = {
  players: [],
  totalPages: 0,
  page: 0,
  limit: 10,
  totalDocs: 906,
  offset: 0,
};

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayers(state, action) {
      state.players = action.payload;
    },
    setPaginate(state, action) {
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.totalDocs = action.payload.totalDocs;
      state.offset = action.payload.offset;
    },
  },
});

export const fetchPlayers = (queryParams) => {
  queryParams.is_active = true;
  return (dispatch) => {
    axiosIns.get("/players", { params: queryParams }).then((res) => {
      dispatch(playerActions.setPlayers(res.data.data.players.docs));
      let paginate = {
        totalPages: res.data.data.players.totalPages,
        page: res.data.data.players.page,
        limit: res.data.data.players.limit,
        totalDocs: res.data.data.players.totalDocs,
        offset: res.data.data.players.offset,
      };
      dispatch(playerActions.setPaginate(paginate));
    });
  };
};

export const playerActions = playerSlice.actions;

export default playerSlice.reducer;

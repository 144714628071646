import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = { standings: [] };

const standingSlice = createSlice({
  name: "standing",
  initialState,
  reducers: {
    fetchStandings(state, action) {
      state.standings = action.payload;
    },
  },
});

export const fetchStandings = (season) => {
  return (dispatch) => {
    axiosIns
      .get("/standings", { params: { season: season || process.env.REACT_APP_SEASON } })
      .then((res) => {
        dispatch(
          standingActions.fetchStandings(
            res.data.data.standings.docs[0].standings
          )
        );
      });
  };
};

export const standingActions = standingSlice.actions;

export default standingSlice.reducer;

import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/* import { useTranslation } from "react-i18next"; */
import Tooltip from "@mui/material/Tooltip";

const ModalPlayer = ({ open, handleClose, player }) => {
/*   const { t, i18n } = useTranslation(); */
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    maxWidth: 1024,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    backgroundColor: "#1D1933",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflow: "auto",
  };

  const getPoint = (fixture) => {

    let point = 0;

    if (!fixture) {
      return 0;
    }

    let position = fixture.games.position;

    if (fixture.games.minutes >= 60) {
      point += 2;
    } else if (fixture.games.minutes > 0) {
      point += 1;
    }

    if (position === "G" || position === "D") {
      point += fixture.goals.total * 6;
    } else if (position === "M") {
      point += fixture.goals.total * 5;
    } else if (position === "F") {
      point += fixture.goals.total * 4;
    }

    // For each assist for a goal	3
    point += fixture.goals.assists * 3;

    // clean sheet 在場上至少60分鐘(不包括傷停補時)没有失球的情況下，將獲得clean sheet獎勵

    // For every 3 shots saved by a goalkeeper 	1
    if (position === "G") {
      point += Math.floor(fixture.goals.saves / 3);
    }

    // For each penalty save	5
    point += fixture.penalty.saved * 5;

    // For each penalty miss 	-2
    point += fixture.penalty.missed * -2;

    //For every 2 goals conceded by a goalkeeper or defender	-1
    if (position === "G" || position === "D") {
      point += Math.floor(fixture.goals.conceded / 2) * -1;
    }

    // For each yellow card 	-1
    point += fixture.cards.yellow * -1;

    // For each red card	-3
    point += fixture.cards.red * -3;

    // For goalkeeper, 如果每三次saves 没有一次concended, 則獲得2 points
    if (position === "G") {
      let bonus =
        (Math.floor(fixture.goals.saves / 3) - fixture.goals.conceded) * 2;
      point += bonus <= 0 ? 0 : bonus;
    }

    // For each interception	1
    if (position === "G" || position === "D") {
      point += fixture.tackles.interceptions * 2;
      point += fixture.tackles.blocks;
    }

    if (position === "G" || position === "D") {
      //Every 2 key passes earn 2 points
      point += Math.floor(fixture.passes.key / 2) * 3;
    } else if (position === "M") {
      //Every 2 key passes earn 2 points
      point += Math.floor(fixture.passes.key / 2) * 2;
    } else if (position === "F") {
      //Every 3 key passes earn 2 points
      point += Math.floor(fixture.passes.key / 2) * 1;
    }

    //Every 20 pass earn 2
    point += Math.floor(fixture.passes.total / 30) * 1;

    return point;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-player-wrapper"
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <img src={player && (player?.image_url || player.photo)} alt="" />
          </Grid>
          <Grid
            item
            xs={6}
            sm={8}
            className="d-flex justify-content-center align-items-center color-menu flex-column"
          >
            <a className="w-100 " onClick={(e) => e.preventDefault()} href="/">
              {player &&
                player.statistics &&
                player.statistics[0].games.position}
            </a>
            <div className="w-100">
              <Typography variant="h5" component="h2" mt={1} mb={1}>
                {player && player.name}
              </Typography>
            </div>
            <div className="w-100">
              {" "}
              {player && player.statistics && player.statistics[0].team.name}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="player-statistic">
              <div className="player-statistic-wrapper">
                <ul className="d-flex">
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Rating
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].games.rating}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Goals
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {(player &&
                        player.statistics &&
                        player.statistics[0].goals.total) ||
                        "0"}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Shots
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].shots.total}{" "}
                      ({" "}
                      {player &&
                        player.statistics &&
                        player.statistics[0].shots.on}{" "}
                      )
                    </Typography>
                  </li>
                  {player &&
                    player.statistics &&
                    player.statistics[0].goals.saves && (
                      <li className="player-statistic-box">
                        <Typography variant="subtitle2" component="h2">
                          Saves
                        </Typography>
                        <Typography variant="subtitle2" component="h2">
                          {(player &&
                            player.statistics &&
                            player.statistics[0].goals.saves) ||
                            "0"}
                        </Typography>
                      </li>
                    )}
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Assists
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].goals.assists}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Passes
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].passes.total}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Tackles
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].tackles.total}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Duels
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].duels.total}
                      (
                      {player &&
                        player.statistics &&
                        player.statistics[0].duels.won}
                      )
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Dribbles
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].dribbles.attempts}
                      (
                      {player &&
                        player.statistics &&
                        player.statistics[0].dribbles.success}
                      )
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Yellow Cards
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].cards.yellow}
                    </Typography>
                  </li>
                  <li className="player-statistic-box">
                    <Typography variant="subtitle2" component="h2">
                      Red Cards
                    </Typography>
                    <Typography variant="subtitle2" component="h2">
                      {player &&
                        player.statistics &&
                        player.statistics[0].cards.red}
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="activity-table-responsive table-responsive sticky-table-modal">
              <table className="table activity-table standing-table">
                <thead className="sticky-header">
                  <tr>
                    <th scope="col" className="mw-200 fix-header">
                      Game
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <Tooltip title="Points" arrow placement="top">
                        <span>Pts</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Position" arrow placement="top">
                        <span>PN</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Game Started" arrow placement="top">
                        <span>GS</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Rating" arrow placement="top">
                        <span>R</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Minutes played" arrow placement="top">
                        <span>MP</span>
                      </Tooltip>
                    </th>

                    <th scope="col">
                      {" "}
                      <Tooltip title="Goals" arrow placement="top">
                        <span>G</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Assists" arrow placement="top">
                        <span>A</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      <Tooltip title="Save" arrow placement="top">
                        <span>S</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      <Tooltip title="Conceded" arrow placement="top">
                        <span>C</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Shots Total" arrow placement="top">
                        <span>STT</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Shots On" arrow placement="top">
                        <span>STO</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Passes Total" arrow placement="top">
                        <span>PST</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      <Tooltip title="Passes Key" arrow placement="top">
                        <span>PSK</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      <Tooltip title="Passes Accuracy" arrow placement="top">
                        <span>PSA</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      <Tooltip title="Tackles Total" arrow placement="top">
                        <span>TT</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Tackles Blocks" arrow placement="top">
                        <span>TB</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip
                        title="Tackles Interceptions"
                        arrow
                        placement="top"
                      >
                        <span>TI</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Duels Total" arrow placement="top">
                        <span>DUT</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      <Tooltip title="Duels Won" arrow placement="top">
                        <span>DUW</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Fouls Drawn" arrow placement="top">
                        <span>FD</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Fouls Committed" arrow placement="top">
                        <span>FC</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Yellow Card" arrow placement="top">
                        <span>YC</span>
                      </Tooltip>
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Red Card" arrow placement="top">
                        <span>RC</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Penalty Scored" arrow placement="top">
                        <span>PS</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Penalty Missed" arrow placement="top">
                        <span>PS</span>
                      </Tooltip>{" "}
                    </th>
                    <th scope="col">
                      {" "}
                      <Tooltip title="Penalty Saved" arrow placement="top">
                        <span>PS</span>
                      </Tooltip>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {player.fixtureStatistics &&
                    player.fixtureStatistics.map((fixture, index) => (
                      <tr key={index}>
                        <th
                          scope="row"
                          className="author sticky-sm-start sticky-col mw-200"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              className="standing-team-logo"
                              src={fixture.fixture.teams.home.logo}
                              alt=""
                            />{" "}
                            <span className="color-menu mr-1">vs</span>
                            <img
                              className="standing-team-logo"
                              src={fixture.fixture.teams.away.logo}
                              alt=""
                            />
                          </div>
                        </th>
                        <td>
                          {fixture.fixture.goals.home >= 0 && (
                            <span className="color-menu">
                              {fixture.fixture.goals.home}:{" "}
                              {fixture.fixture.goals.away}
                            </span>
                          )}
                        </td>
                        <td>{getPoint(fixture)}</td>
                        <td>{fixture.games.position}</td>
                        <td>{fixture.games.substitute ? 0 : 1}</td>
                        <td>{fixture.games.rating}</td>
                        <td>{fixture.games.minutes}</td>

                        <td>
                          {fixture.goals.total === null
                            ? "0"
                            : fixture.goals.total}
                        </td>
                        <td>
                          {fixture.goals.assists === null
                            ? "0"
                            : fixture.goals.assists}
                        </td>
                        <td>
                          {fixture.goals.saves === null
                            ? "0"
                            : fixture.goals.saves}
                        </td>
                        <td>
                          {fixture.goals.conceded === null
                            ? "0"
                            : fixture.goals.conceded}
                        </td>
                        <td>
                          {fixture.shots.total === null
                            ? "0"
                            : fixture.shots.total}
                        </td>

                        <td>
                          {fixture.shots.on === null ? "0" : fixture.shots.on}
                        </td>

                        <td>
                          {fixture.passes.total === null
                            ? "0"
                            : fixture.passes.total}
                        </td>

                        <td>
                          {fixture.passes.key === null
                            ? "0"
                            : fixture.passes.key}
                        </td>

                        <td>
                          {fixture.passes.accuracy === null
                            ? "0"
                            : fixture.passes.accuracy}
                        </td>

                        <td>
                          {fixture.tackles.total === null
                            ? "0"
                            : fixture.tackles.total}
                        </td>
                        <td>
                          {fixture.tackles.blocks === null
                            ? "0"
                            : fixture.tackles.blocks}
                        </td>
                        <td>
                          {fixture.tackles.interceptions === null
                            ? "0"
                            : fixture.tackles.interceptions}
                        </td>
                        <td>
                          {fixture.duels.total === null
                            ? "0"
                            : fixture.duels.total}
                        </td>
                        <td>
                          {fixture.duels.won === null ? "0" : fixture.duels.won}
                        </td>
                        <td>
                          {fixture.fouls.drawn === null
                            ? "0"
                            : fixture.fouls.drawn}
                        </td>
                        <td>
                          {fixture.fouls.committed === null
                            ? "0"
                            : fixture.fouls.committed}
                        </td>
                        <td>{fixture.cards.yellow}</td>
                        <td>{fixture.cards.red}</td>
                        <td>
                          {fixture.penalty.scored === null
                            ? "0"
                            : fixture.penalty.scored}
                        </td>
                        <td>
                          {fixture.penalty.missed === null
                            ? "0"
                            : fixture.penalty.missed}
                        </td>
                        <td>
                          {fixture.penalty.saved === null
                            ? "0"
                            : fixture.penalty.saved}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalPlayer;

import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";

const initialState = { faqs: [], faqCategories: [] };

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    fetchFaqs(state, action) {
      state.faqs = action.payload;
    },
    fetchFaqCategories(state, action) {
      state.faqCategories = action.payload;
    },
  },
});

export const fetchFaqs = (data) => {
  return (dispatch) => {
    axiosIns.get("/faqs/list", { params: data }).then((res) => {
      dispatch(faqActions.fetchFaqs(res.data.data.faqs.docs));
    });
  };
};

export const fetchFaqCategories = (data) => {
  return (dispatch) => {
    axiosIns.get("/faq-categories/list", { params: data }).then((res) => {
      // console.log("res", res.data.data.faqCategories.docs);
      dispatch(faqActions.fetchFaqCategories(res.data.data.faqCategories.docs));
    });
  };
};

export const faqActions = faqSlice.actions;

export default faqSlice.reducer;

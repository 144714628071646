import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FloatingButton from "../../Components/Button/FloatingButton";
import Web3Context from "../../context/Web3Context";
import { useSelector } from "react-redux";
import {TokenBalanceContext} from "../../context/TokenBalanceContext";
import {getName} from "../../libs/helper"
import Swal from "sweetalert2";
import {
  CircleSpinnerOverlay,
} from "react-spinner-overlay";
import MainBanner from "../IndexOne/MainBanner";

const ManagerPage = ({ manager, content, players, token }) => {
  const { t, i18n } = useTranslation();
  //const token_contract_address = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
  const management_contract_address =
    process.env.REACT_APP_MANAGEMENT_CONTRACT_ADDRESS;

  const profileStyle = {
    backgroundImage: `url(${manager?.image_url})`,
    width: "100%",
    aspectRatio: "2/3",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const [filter, setFilter] = useState(null);
  const [filteredPlayers, setFilteredPlayers] = useState(players);
  const [loading, setLoading] = useState(false);
  const { web3, managementContract, tokenContract, provider } =
    useContext(Web3Context);
  const { tokenBalance, tokenSymbol, refetchTokenBalance } =
    useContext(TokenBalanceContext);
  const account = useSelector((state) => state.web.account);
  const [nftBalance, setNftBalance] = useState(0);
  const [managerNftContract, setManagerNftContract] = useState(null);


  const fetchNftBalance = useCallback(async () => {
    if(account && managerNftContract && web3)
    {
      console.log('fetchNftBalance')
      try{
        const results = await managerNftContract.methods.balanceOf(account).call();
        setNftBalance(results);
        return results;

      }catch(err){
        setNftBalance(0);
        return 0;
      }
    }
  }, [managerNftContract, account, web3])

  useEffect(() => {
    async function createManagerNftContract(){
      if (!web3) return null;
      const managerNftRes = await fetch('/abi/ManagerNft.abi');
      const managerNftAbi = await managerNftRes.json();
      setManagerNftContract(new web3.eth.Contract(managerNftAbi, manager.contract_address));
    }

    if(web3)
    {
      createManagerNftContract();
    }
  }, [web3, manager.contract_address])

  useEffect(() => {

    fetchNftBalance();

  }, [managerNftContract, account, web3])


  useEffect(() => {
    if (filter) {
      setFilteredPlayers(
        players.filter(
          (player) => player?.statistics[0]?.games.position === filter
        )
      );
    } else {
      setFilteredPlayers(players);
    }
  }, [players, filter]);

  const updateFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const getTokenAllowance = useCallback(async () => {
    if (account && tokenContract) {
      try {
        const results = await tokenContract.methods
          .allowance(account, management_contract_address)
          .call({ from: account });

        const allowance = web3.utils.fromWei(results, "ether");
        return allowance;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  }, [tokenContract, account, web3]);


  const approve = useCallback(
    async (_amount) => {
      setLoading(true);
      const value = await web3.utils.toWei(`${_amount}`, "ether");
      let result = false;
      await tokenContract.methods
        .approve(management_contract_address, value)
        .send({ from: account })
        .on("transactionHash", function (hash) {
          /*  toast.success(<SuccessToast message="ApprovingMessage" />, {
        hideProgressBar: false,
      }); */
          console.log("transactionHash", hash);
        })
        .on("receipt", function (receipt) {
          /* toast.success(<SuccessToast message="FinishApproveMessage" />, {
        hideProgressBar: false,
      }); */
          // Perform desired action after success
          //console.log("Perform desired action after success", _grade, _amount);
          result = true;
        })
        .catch((error) => {
          setLoading(false);
        });
      return result;
    },
    [web3, tokenContract, account]
  );

  const buyManagerNft = useCallback(
    async (_nft_contract_address) => {
      setLoading(true);
      await managementContract.methods
        .buyManagerNft(_nft_contract_address)
        .send({ from: account })
        .on("transactionHash", function (hash) {
          console.log("Transaction hash: ", hash);
          Swal.fire({
            title: `${t("manager.warning_after_purchase_title")}`,
            html: `${t("manager.warning_after_purchase_text1")} ${content && content.name} NFT${t("manager.warning_after_purchase_text2")}<a target="_blank" href="${process.env.REACT_APP_BLOCK_EXPLORER}/tx/${hash}">${hash}</a>`,
            confirmButtonText: `${t("manager.warning_after_purchase_button")}`,
            width: 600,
            padding: "2em",
            color: "#716add",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
              rgba(0,0,123,0.4)
            `,
          });
        })
        .on("receipt", function (receipt) {
          fetchNftBalance();
          refetchTokenBalance();
          // Perform desired action after success
          Swal.fire({
            title: `${t("manager.warning_finish_title")}`,
            text: `${t("manager.warning_finish_text")} ${content && content.name} NFT${t("manager.warning_finish_text2")}`,
            confirmButtonText: `${t("manager.finish")}`,
            width: 600,
            padding: "2em",
            color: "#716add",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
              rgba(0,0,123,0.4)
            `,
          });
          
        })
        .then((result) => {
          setLoading(false);
          //console.log(newContractInstance);
          console.log("result", result);
        })
        .catch((error) => {
          setLoading(false);
        });
    },
    [managementContract, account, content, fetchNftBalance]
  );

  const handleBuyNFT = useCallback(
    async (e) => {
      e.preventDefault();
      let tokenAllowanceResult = await getTokenAllowance();
      console.log({ tokenAllowanceResult, token });
      console.log('manager_contract_address', manager?.contract_address)
      if (!account) {
        if (account && provider) {
          provider.enable();
        } else {
          provider.request({ method: "eth_requestAccounts" });
        }
      } else if (Number(tokenBalance) < Number(token)) {
        console.log('tokenBalance not enough')
        console.log('tokenBalance', tokenBalance)
        console.log('token', token)
        Swal.fire({
          title: `${t("manager.warning_not_enough_token1")} ${token} ${
            process.env.REACT_APP_TOKEN_NAME
          } ${t("manager.warning_not_enough_token2")} ${
            content && content.name
          } NFT.`,
          width: 600,
          padding: "2em",
          color: "#716add",
          //background: `#fff url(${banner && banner.image_url})`,
          backdrop: `
          rgba(0,0,123,0.4)
        `,
        });
      } else if (tokenAllowanceResult < token) {
        Swal.fire({
          title: t("manager.warning_spending_cap_title"),
          text: `${t("manager.warning_spending_cap1")} "${token}" ${t("manager.warning_spending_cap2")} ${content && content.name} NFT`,
          confirmButtonText: `${t("manager.warning_spending_cap_button")}`,
          width: 600,
          padding: "2em",
          color: "#716add",
          //background: `#fff url(${banner && banner.image_url})`,
          backdrop: `
          rgba(0,0,123,0.4)
        `,
        }).then(async (res) => {
          let result = await approve(token);
          if (!result) return;
          Swal.fire({
            title: `${t("manager.warning_approved_amount")}`,
            text: `${t("manager.warning_approved_amount_text1")} ${content && content.name} NFT`,
            confirmButtonText: `${t("manager.warning_approved_amount_button")}`,
            width: 600,
            padding: "2em",
            color: "#716add",
            //background: `#fff url(${banner && banner.image_url})`,
            backdrop: `
            rgba(0,0,123,0.4)
          `,
          }).then(async () => {
            buyManagerNft(manager?.contract_address);
          });
        });
      } else {
        buyManagerNft(manager?.contract_address);
      }
    },
    [managementContract, account, token, getTokenAllowance, approve, provider, tokenBalance, manager, content]
  );

  return (
    <>
      <MainBanner
      slug="manager"
      title={(content && content.name) || "Manager"}
      subtitle={(nftBalance != 0?t("manager.owned"):"")}
    />
    <div className="author-profile-area">
      <CircleSpinnerOverlay
        loading={loading}
        overlayColor="rgba(0,0,123,0.4)"
        color="#716add"
        size="50"
      />
      
      {(account && (!nftBalance || nftBalance == 0) &&  (

        <FloatingButton
          title={`${t("manager.buy")} ${content && content.name}`}
          onClick={handleBuyNFT}
        />

      )) || ( (!nftBalance || nftBalance == 0)  &&
        <FloatingButton
          title={`${t("header.wallet_connect")}`}
          onClick={handleBuyNFT}
        />
      )}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 order-0 order-lg-0">
            <aside className="author-profile-wrap">
              <div className="author-profile-thumb " style={profileStyle}>
                {
                  nftBalance && nftBalance > 0 && (
                    <span className="badge bg-manager">{t("manager.owned")}</span>
                  )
                }
             
              </div>
              <div className="author-info">
                <h5 className="title">{content?.name}</h5>
                <span>
                  {t("manager.contract_address")}:<br></br>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${manager?.contract_address}`}
                  >
                    {manager?.contract_address}
                  </a>
                </span>
                <p>{content?.description}</p>
              </div>
              <ul className="author-collection">
                <li>
                  <p>{t("manager.player")}:</p>
                  <span>{manager?.playerIds?.length}</span>
                </li>
                <li>
                  <p>{t("manager.balance")}:</p>
                  <span>
                    {tokenBalance} {tokenSymbol}
                  </span>
                </li>
                <li>
                  <p>{t("manager.price")}:</p>
                  <span>
                    {token} {tokenSymbol}
                  </span>
                </li>
              </ul>
              <div className="author-social">
                {manager?.facebook &&
                  manager?.instagram &&
                  manager?.youtube &&
                  manager?.twitter && (
                    <h6 className="title">
                      {t("manager.follow_social_media")}:
                    </h6>
                  )}
                <ul>
                  {manager?.facebook && (
                    <li>
                      <a href={`https://www.facebook.com/${manager.facebook}`}>
                        <div className="icon">
                          <i className="fab fa-facebook-f" />
                        </div>{" "}
                        Facebook / @{manager.facebook}
                      </a>
                    </li>
                  )}
                  {manager?.instagram && (
                    <li>
                      <a
                        href={`https://www.instagram.com/${manager.instagram}`}
                      >
                        <div className="icon">
                          <i className="fab fa-instagram" />
                        </div>{" "}
                        Instagram / @{manager.instagram}
                      </a>
                    </li>
                  )}
                  {manager?.youtube && (
                    <li>
                      <a href={`https://www.youtube.com/@${manager.youtube}`}>
                        <div className="icon">
                          <i className="fab fa-youtube" />
                        </div>{" "}
                        Youtube / @{manager.youtube}
                      </a>
                    </li>
                  )}

                  {manager?.twitter && (
                    <li>
                      <a href={`https://twitter.com/${manager.youtube}`}>
                        <div className="icon">
                          <i className="fab fa-twitter" />
                        </div>{" "}
                        Twitter / @{manager.twitter}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </aside>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="author-product-meta">
              <ul>
                <li
                  className={`${filter === null ? "active" : ""}`}
                  onClick={() => updateFilter(null)}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    {t("manager.all")}
                  </a>
                </li>
                <li
                  className={`${filter === "Attacker" ? "active" : ""}`}
                  onClick={() => updateFilter("Attacker")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    {t("manager.attacker")}
                  </a>
                </li>
                <li
                  className={`${filter === "Midfielder" ? "active" : ""}`}
                  onClick={() => updateFilter("Midfielder")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    {t("manager.midfielder")}
                  </a>
                </li>
                <li
                  className={`${filter === "Defender" ? "active" : ""}`}
                  onClick={() => updateFilter("Defender")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    {t("manager.defender")}
                  </a>
                </li>
                <li
                  className={`${filter === "Goalkeeper" ? "active" : ""}`}
                  onClick={() => updateFilter("Goalkeeper")}
                >
                  <a onClick={(e) => e.preventDefault()} href="/">
                    {t("manager.goalkeeper")}
                  </a>
                </li>
              </ul>   
            </div>
            <div className="row justify-content-center">
              {filteredPlayers &&
                filteredPlayers.map((player, index) => (
                  <div className="col-xl-4 col-md-6 col-sm-6" key={index}>
                    <Link to={`/player-detail/${player._id}`}>
                      <div className="top-collection-item">
                        <div className="collection-item-top">
                          <ul>
                            <li className="avatar">
                              <a
                                onClick={(e) => e.preventDefault()}
                                href="/"
                                className="thumb"
                              >
                                <img
                                  src={player.statistics[0].team.logo}
                                  alt=""
                                />
                              </a>{" "}
                              <a
                                onClick={(e) => e.preventDefault()}
                                href="/"
                                className="name"
                              >
                                {player.statistics[0].team.name}
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="collection-item-thumb">
                          <a onClick={(e) => e.preventDefault()} href="/">
                            <img src={player?.image_url || player.photo} alt="" />
                          </a>
                        </div>
                        <div className="collection-item-content">
                          <h5 className="title">
                            <a onClick={(e) => e.preventDefault()} href="/">
                              {getName(player,i18n.language)}
                            </a>{" "}
                            <span className="price">
                              {player.statistics[0].games.position}
                            </span>
                          </h5>

                          <h6 className="title">
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="color-menu"
                            >
                              {filter === "statistics.goals.saves"
                                ? "Saves"
                                : "Goals"}
                            </a>{" "}
                            <span className="price">
                              {filter === "statistics.goals.saves"
                                ? player.statistics[0].goals.saves
                                : player.statistics[0].goals.total}
                            </span>
                          </h6>

                          <h6 className="title">
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="color-menu"
                            >
                              Assists
                            </a>{" "}
                            <span className="price">
                              {player.statistics[0].goals.assists}
                            </span>
                          </h6>

                          <h6 className="title">
                            <a
                              onClick={(e) => e.preventDefault()}
                              href="/"
                              className="color-menu"
                            >
                              Passes
                            </a>{" "}
                            <span className="price">
                              {player.statistics[0].passes.total}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ManagerPage;

import React from "react";
import { Link } from "react-router-dom";


const SideBar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-logo mb-25">
        <a href="/#">
          <img
            src="/assets/img/logo/logo-epl.png"
            alt=""
            className="sidebar-logo-img"
          />
        </a>
      </div>
      <div className="sidebar-icon">
        <ul>
          <li className="">
            <Link to="/standing">
              <i className="fa fa-trophy" />
            </Link>
          </li>
          <li className="">
            <Link to="/player">
              <i className="fa fa-users" />
            </Link>
          </li>
          <li className="">
            <Link to="/fixture">
              <i className="fa fa-futbol" />
            </Link>
          </li>

          <li className="">
            <Link to="/faq">
              <i className="fa fa-question-circle" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;

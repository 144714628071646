import { createSlice } from "@reduxjs/toolkit";
import axiosIns from "../libs/axios";
import { teamActions } from './team.js';

const initialState = {
  season: {},
  pages:[],
  banners:[],
  managerIds: [],
  playerIds: [],
  managers: [],
  players: [],
  positions: ["Attacker", "Midfielder", "Defender", "Goalkeeper"],
  gameweek: {},
  squadweek: {},
  squad:null,
  balance: 0,
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setseason(state, action) {
      state.season = action.payload;
    },
    setpages(state, action) {
      state.pages = action.payload;
    },
    setbanners(state, action){
      state.banners = action.payload;
    },
    setmanagerIds(state, action){
      state.managerIds = action.payload;
    },
    setplayerIds(state, action){
      state.playerIds = action.payload;
    },
    setmanagers(state, action){
      state.managers = action.payload;
    },
    setPlayers(state, action){
      state.players = action.payload;
    },
    setGameweek(state, action){
      state.gameweek = action.payload;
    },
    setSquadweek(state, action){
      state.squadweek = action.payload;
    },
    setSquad(state, action){
      state.squad = action.payload;
    },
    setBalance(state, action){
      state.balance = action.payload;
    }
  },
});

//Find out the highest rating = ?sortBy=statistics.games.rating&sortDesc=true
export const fetchSeason = (accessToken) => {
  return (dispatch) => {
    axiosIns.get("/info/season",{
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    }).then((res) => {
      dispatch(infoActions.setseason(res.data.data.season));
      //console.log('pages',res.data.data.pages)
      dispatch(infoActions.setpages(res.data.data.pages));
      dispatch(infoActions.setbanners(res.data.data.banners));
      dispatch(infoActions.setSquad(res.data.data.squad));
      dispatch(teamActions.setTeams(res.data.data.teams));
      dispatch(infoActions.setBalance(res.data.data.balance?.current_balance || 0));
    });
  };
};

//Get the managers that owned by the user
export const fetchManagers = (accessToken) => {
  return (dispatch) => {
    axiosIns.get("/gamers/managers", {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    }).then(res => {
      dispatch(infoActions.setmanagerIds(res.data.managerIds));
      dispatch(infoActions.setplayerIds(res.data.playerIds));
      dispatch(infoActions.setmanagers(res.data.managers));
      dispatch(infoActions.setPlayers(res.data.players));
    }) 
  }
}

export const fetchSquad = (accessToken) => {
  return (dispatch) => {
    axiosIns.get("/squads/", {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    }).then(res => {
      console.log('squad', res.data.squad);
      
      dispatch(infoActions.setSquad(res.data.squad));
    })
  }
}

export const fetchCurrentGameWeek = () => {
  return (dispatch) => {
    axiosIns.get("/game-weeks/current").then(res => {
      //console.log('current gameweek', res);
      dispatch(infoActions.setGameweek(res.data.data.game_week));
    })
  }
}

export const fetchCurrentSquadWeek = () => {
  return (dispatch) => {
    axiosIns.get("/game-weeks/squad").then(res => {
      //console.log('current gameweek', res);
      dispatch(infoActions.setSquadweek(res.data.squadweek));
    })
  }
}

export const addSquad = (squad, accessToken) => {
  return new Promise((resolve, reject) => {
    axiosIns
      .post("/squads/", squad,{
        headers: {
          Authorization: "Bearer " + accessToken,
        }
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export const fetchBalance = (accessToken) => {
  return (dispatch) => {
    axiosIns.get("/balances/", {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    }).then(res => {
      dispatch(infoActions.setBalance(res.data.balance?.current_balance || 0));
    })
  }
}


export const infoActions = infoSlice.actions;

export default infoSlice.reducer;
